import {Sidebar} from "./sidebar.tsx";
import {ChatHistory} from "./chat-history.tsx";
import {getUser} from "../service/UserService.ts";
import {cn} from "../lib/utils.ts";
import {useLocation, useNavigate} from "react-router-dom";
import KnowledgeTreeNode from "./knowledge-base/KnowledgeTreeNode.tsx";
import {useKnowledgeBase} from "../context/knowledgeBaseContext.tsx";
import {Button} from "./ui/button.tsx";
import {newChat} from "../service/NavigationService.ts";
import {useMessages} from "../context/messagesContext.tsx";


export function SidebarDesktop() {
    const user = getUser();
    const location = useLocation();
    const path = location.pathname;
    const {setMessages, thread, setThread} = useMessages();

    const {knowledgeBaseTree, expandedIds, handleNodeSelect} = useKnowledgeBase();
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (thread) {
            navigate(`/chat/${thread.id}`); // Go back if there is history
        } else {
            // No history, create a new chat and redirect
            newChat(setMessages, setThread);
            navigate('/'); // Redirect to home or default route
        }
    };

    if (!user) {
        return null;
    }

    const isKnowledgeBase = path.includes('knowledge-base');
    return (
        <Sidebar
            forceOpen={isKnowledgeBase}
            className={cn(
                "peer absolute inset-y-0 z-30 hidden border-r bg-muted duration-300 ease-in-out",
                "lg:flex", // Sidebar is always visible in lg+ screens
                "data-[state=open]:w-[250px] xl:data-[state=open]:w-[265px]", // Full width when open
                "data-[state=collapsed]:w-[80px]", // Minimal width when collapsed
            )}
        >
            {isKnowledgeBase && knowledgeBaseTree && <>
                <Button
                    variant="ghost"
                    onClick={handleBackClick}
                    className={cn(
                        "flex items-center rounded-lg bg-white h-12 text-blue-900 mt-5 mb-5",
                        "px-4 py-2 w-full justify-start",
                        "hover:bg-grey-300"
                    )}
                >
                    <span className="material-symbols-outlined mr-2 text-[16px] leading-none">arrow_back</span>
                    <span>Back to chats</span>
                </Button>
                <div className="h-[calc(100vh-64px)] overflow-y-auto">
                    <KnowledgeTreeNode
                        node={knowledgeBaseTree}
                        onNodeSelect={handleNodeSelect} // Pass setSearchParams properly
                        expandedIds={expandedIds}
                    />
                </div>
            </>}
            {!isKnowledgeBase && <ChatHistory/>}
        </Sidebar>
    );
}
