import * as React from 'react'
import {useState} from 'react'
import {ChatHistory} from './chat-history'
import {Link, useNavigate} from 'react-router-dom'
import {SidebarMobile} from "./sidebar-mobile.tsx";
import {useMessages} from "../context/messagesContext.tsx";
import {newChat} from "../service/NavigationService.ts";
import {
    getEmail,
    getUser,
    hasImpersonationPermission,
    isUserFromDomain,
    setUser,
    toggleDisableStaff
} from "../service/UserService.ts";
import {getCollectionsName, getLogo} from "../service/ConfigService.ts";
import {InitialsIcon24} from "./ui/initials.tsx";
import {updateActiveCollection} from "../service/admin/adminUserService.ts";

function UserOrLogin() {
    const user = getUser()
    const {setMessages, setThread} = useMessages();

    return (
        <>
            {user && (
                <>
                    <SidebarMobile>
                        <ChatHistory/>
                    </SidebarMobile>
                </>
            )}
            <div className="">
                <Link to="/" rel="nofollow" onClick={() => {
                    newChat(setMessages, setThread);
                }}>
                    <img src={getLogo()} alt="Logo"
                         className="h-[20px] w-auto"/> {/* Adjust logo height */}
                </Link>
            </div>
        </>
    )
}

function Badges() {
    const [user, setUserComponent] = useState(getUser()); // Use useState to ensure the component re-renders on user update
    const collections = getCollectionsName();
    const navigate = useNavigate();

    const updateRole = async (role: string) => {
        const userUpdated = await updateActiveCollection(role, navigate);
        setUser(userUpdated);
        setUserComponent(userUpdated);
    };

    return (
        <div className="flex items-center space-x-2 mr-2">
            {collections.map((role) => {
                const isActive = user?.active_collection_name === role;
                const roleWithUpperCase = role.charAt(0).toUpperCase() + role.slice(1);
                return (
                    <span
                        key={role}
                        className={`text-sm font-semibold px-2 py-1 rounded-full cursor-pointer ${isActive ? 'bg-green-500 text-white' : 'bg-gray-400 text-gray-200'}`}
                        onClick={() => updateRole(role)}
                        title={`For all questions related to ${roleWithUpperCase}`}
                    >
                        {roleWithUpperCase}
                    </span>
                );
            })}
        </div>
    );
}


export function Header() {
    return (
        <header
            className="relative top-0 z-50 flex items-center justify-between w-full h-14 px-4 border-b shrink-0 bg-blue-900">
            <div className="flex items-center">
                <React.Suspense fallback={<div className="flex-1 overflow-auto"/>}>
                    <UserOrLogin/>
                </React.Suspense>
            </div>

            {(isUserFromDomain() || hasImpersonationPermission()) && <Badges/>}

            <div
                className="flex items-center bg-[#2a3a4d] text-white rounded-lg pl-2 py-1 pr-1 shadow-md h-8 font-medium space-bet border border-white/5">
                <div className="mr-2" onDoubleClick={toggleDisableStaff}>
                    <span className="hidden md:block">{getEmail()}</span>
                </div>
                <div>
                    <InitialsIcon24/>
                </div>
            </div>
        </header>
    );
}
