import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";
import {MessageThreadEvaluation} from "../../models/messageModel.ts";

export type ThreadsFilters = {
    currentPage?: number;
    evaluation?: MessageThreadEvaluation;
    startDate?: string;
    endDate?: string;
    email?: string;
    collectionName?: string;
    supportAsked?: boolean;
}

const getURLSearchParams = (threadsFilters: ThreadsFilters): URLSearchParams => {
    return new URLSearchParams({
        page: String(threadsFilters.currentPage ?? '1'),
        evaluation: String(threadsFilters.evaluation ?? ''),
        start_date: threadsFilters.startDate ? new Date(threadsFilters.startDate).toISOString() : '',
        end_date: threadsFilters.endDate ? new Date(threadsFilters.endDate).toISOString() : '',
        email: String(threadsFilters.email ?? ''),
        collection_name: String(threadsFilters.collectionName ?? ''),
        support_asked: String(threadsFilters.supportAsked ?? ''),
    });
}


export async function getAllThreads(navigate: NavigateFunction, threadsFilters: ThreadsFilters): Promise<any> {
    try {
        const params = getURLSearchParams(threadsFilters);
        // Construct the API endpoint with query parameters
        const apiUrl = `/api/business_messages/all-threads-history?${params.toString()}`;
        const response = await fetchWithAuth(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function exportThreads(navigate: NavigateFunction, threadsFilters: ThreadsFilters): Promise<void> {
    const params = getURLSearchParams(threadsFilters);
    try {
        const response = await fetchWithAuth(`/api/admin/export-threads?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        // Check if the response is ok and contains a CSV file
        if (response.ok) {
            const blob = await response.blob(); // Convert response to blob
            const url = window.URL.createObjectURL(blob); // Create a download URL

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'AMI-Conversations.csv');
            document.body.appendChild(link); // Append the link to the DOM
            link.click(); // Programmatically click the link to trigger the download
            link.remove(); // Clean up the link element
        } else {
            await getJsonAndHandleError(response, navigate); // Handle errors like JSON if needed
        }
    } catch (error) {
        navigate('/');
    }
}

export async function evaluateThread(thread_id: string, evaluation: string, navigate: NavigateFunction): Promise<void> {

    if (!thread_id) {
        console.log('Thread ID is missing')
        return
    }

    try {
        const response = await fetchWithAuth(`/api/business_messages/sync/thread-evaluation`, {
            method: 'POST',
            body: JSON.stringify({"evaluation": evaluation, id: thread_id}),
        })
        await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}

export async function evaluateCommentThread(thread_id: string, evaluationComment: string, category: string, navigate: NavigateFunction): Promise<void> {

    if (!thread_id) {
        console.log('Thread ID is missing')
        return
    }

    try {
        const response = await fetchWithAuth(`/api/business_messages/sync/thread-evaluation-comment`, {
            method: 'POST',
            body: JSON.stringify({
                "evaluation_comment": evaluationComment,
                "evaluation_category": category,
                id: thread_id
            }),
        })
        await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}
