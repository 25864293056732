import * as React from 'react'
import Textarea from 'react-textarea-autosize'
import {Button} from './ui/button'
import {Tooltip, TooltipContent, TooltipTrigger} from './ui/tooltip'
import {useEnterSubmit} from '../lib/hooks/use-enter-submit'
import {useNavigate} from 'react-router-dom'
import {useMessages} from "../context/messagesContext.tsx";


export function PromptForm({isStaff}: { isStaff: boolean | undefined }) {
    const {formRef, onKeyDown} = useEnterSubmit()
    const inputRef = React.useRef<HTMLTextAreaElement>(null)

    const {
        input,
        setInput,
        handleInputChange,
        submitUserMessage,
        messages
    } = useMessages();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return (
        <form
            ref={formRef}
            onSubmit={async (e: any) => {
                e.preventDefault()

                // Blur focus on mobile
                if (window.innerWidth < 600) {
                    e.target['message']?.blur()
                }

                const value = input.trim()
                setInput('')
                await submitUserMessage(value, navigate, isStaff);
            }
            }
        >
            <div
                className="relative shadow-md flex max-h-60 w-full grow flex-col overflow-hidden bg-white sm:rounded-md sm:border mb-8">
                <div className="flex items-center px-2 py-[16px] w-full">
                    <Textarea
                        ref={inputRef}
                        tabIndex={0}
                        onKeyDown={onKeyDown}
                        placeholder={messages.length == 0 ? "Describe your problem. Ex: How can I create products on a marketplace?" : ""}
                        className="px-2 min-h-[40px] max-h-52 w-full resize-none placeholder-italic border border-background focus:border-blue-300 focus:outline-none focus:border-2 rounded-md"
                        autoFocus
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        name="message"
                        rows={1}
                        value={input}
                        onChange={handleInputChange}
                        style={{
                            flex: '1',
                            marginRight: '0.75rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            lineHeight: '1.25rem', // Adjust line-height to match the height of the textarea
                        }}
                    />
                    <div className="bg-blue-900 rounded-md">
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button type="submit" size="icon" disabled={input === ''} className="h-10 w-10">
                                    <span
                                        className="material-symbols-outlined text-white text-[16px]">arrow_upward</span>
                                    <span className="sr-only">Send message</span>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Send message</TooltipContent>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </form>
    )
}
