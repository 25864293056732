import {useEffect, useRef} from "react";
import Player from "@vimeo/player";

interface VideoVimeoPlayerProps {
    vimeoId: number;
}

export const VideoVimeoPlayer: React.FC<VideoVimeoPlayerProps> = ({vimeoId}) => {
    const playerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (playerRef.current) {
            const player = new Player(playerRef.current, {
                id: vimeoId,
                width: 480,
                autoplay: false,
                controls: true,
                loop: false,
            });

            return () => {
                player.destroy(); // Clean up when the component unmounts
            };
        }
    }, [vimeoId]);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2rem auto',
                padding: '1rem',
                maxWidth: '600px',
                border: '2px solid #e0e0e0',
                borderRadius: '8px',
                background: '#f9f9f9',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            <div
                ref={playerRef}
                style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: '0',
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                }}
            />
        </div>
    );
};