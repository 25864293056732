import React from 'react';
import {cn} from "../lib/utils.ts";
import {Link} from "react-router-dom";
import {MessageThread, MessageThreadEvaluation} from "../models/messageModel.ts";
import {isUserFromDomain} from "../service/UserService.ts";
import {FaEnvelope, FaRegCalendarAlt} from 'react-icons/fa';

interface ChatListProps {
    chats: MessageThread[];
}

const ChatThreadList: React.FC<ChatListProps> = ({chats}) => {
    const evaluationBadgeColor: Record<MessageThreadEvaluation, string> = {
        OK: 'bg-green-100 text-green-800',
        KO: 'bg-red-100 text-red-800',
        NEUTRAL: 'bg-yellow-100 text-yellow-800',
        NOT_EVALUATED: 'bg-gray-100 text-gray-800',
    };

    return (
        <div className={cn("pt-4 md:pt-2")}>
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Chat List</h2>
            <ul className="space-y-1">
                {chats.map((chat) => (
                    <li
                        key={chat.id}
                        className={cn(
                            "list-none p-4 rounded-lg shadow-md hover:bg-gray-200 transition duration-300",
                            isUserFromDomain() ? "bg-blue-200" : "bg-white"
                        )}
                    >
                        <Link to={`/admin/chat/${chat.id}`}>
                            <div className="flex justify-between items-center mb-2">
                                <div className="flex items-center">
                                    <div className="font-semibold text-xl text-gray-900 mr-2 relative">
                                        {chat.title ?? 'No title'}
                                        {chat.messages_count > 0 && (
                                            <span
                                                className="absolute -top-1 -right-6 bg-red-200 text-black text-sm font-bold rounded-full px-1.5">
                                                {chat.messages_count}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center text-gray-500">
                                    <div className="flex flex-wrap gap-2 mr-2">
                                        {chat.collection_names?.map((collection, index) => (
                                            <span
                                                key={index}
                                                className="bg-blue-200 text-blue-800 px-2 py-0.5 rounded text-sm font-medium"
                                            >
                                                {collection}
                                            </span>
                                        ))}
                                    </div>
                                    <FaRegCalendarAlt className="mr-2"/>
                                    {chat.creation_date && new Date(chat.creation_date).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <div className="flex items-center text-gray-700">
                                    <FaEnvelope className="mr-2"/>
                                    <span className="font-medium">User Email:</span> {chat.user.email}
                                </div>
                                <div className="flex items-center">
                                    {chat.ask_to_talk_to_human && (
                                        <div
                                            className="flex items-center bg-purple-100 text-purple-800 px-2 py-0.5 rounded text-sm font-medium mr-2">
                                            <span className="material-symbols-outlined text-[1.125rem] mr-1">face</span>
                                            <span>Support asked</span>
                                        </div>
                                    )}
                                    <div
                                        className={cn(
                                            "inline-flex items-center px-2 py-0.5 rounded text-sm font-medium",
                                            evaluationBadgeColor[chat.evaluation]
                                        )}
                                    >
                                        {chat.evaluation === 'NOT_EVALUATED' ? 'Not evaluated' : chat.evaluation}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChatThreadList;