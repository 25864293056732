import {getAgoConfig} from "./ConfigService.ts";

export const setToken = (token: string) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('token', token)
    }
}

export const getToken = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('token')
    }
    return null
}

export const setUser = (user: any) => {
    if (typeof window !== 'undefined' && user && user !== 'undefined') {
        localStorage.setItem('user', JSON.stringify(user))
    }
}

export const getUser = () => {
    if (typeof window !== 'undefined') {
        const u = localStorage.getItem('user')

        return u ? JSON.parse(u) : null
    }
    return null
}

export const toggleDisableStaff = () => {
    const user = getUser()
    user.disableStaff = !user.disableStaff
    setUser(user)
}

export const isStaff = () => {
    const user = getUser()

    if (!user) {
        return false
    }

    if (user.disableStaff) {
        return false
    }

    return user?.is_staff
}

export const logout = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }
}

export const getInitials = (email: string | null = null): string => {
    if (!email) {
        email = getEmail()
    }
    if (email) {
        const namePart = email.split('@')[0];
        const nameParts = namePart.split(/[._-]/); // Splits on common separators
        return nameParts
            .map((part: string) => part.charAt(0).toUpperCase())
            .join('');
    }
    return '';
}

export const getEmail = (): string | null => {
    const user = getUser()

    if (!user) {
        return null;
    }

    return user?.email ?? ''
}

export const isUserFromDomain = (): boolean => {
    const email = getEmail();
    const config = getAgoConfig();
    const domain = config.emailDomainName;

    if (!email || !domain) {
        return false;
    }

    return email.split('@').pop()?.toLowerCase() === domain;
}

export const hasImpersonationPermission = (): boolean => {
    const user = getUser()

    if (!user) {
        return false;
    }

    return user.permissions?.includes('impersonate')
}