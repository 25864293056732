import {ButtonScrollToBottom} from './button-scroll-to-bottom'
import {PromptForm} from './prompt-form'
import {FooterText} from './footer'
import {useMessages} from "../context/messagesContext.tsx";
import {useNavigate} from "react-router-dom";
import {getAgoConfig} from "../service/ConfigService.ts";

export interface ChatPanelProps {
    id?: string
    title?: string
    isAtBottom: boolean
    scrollToBottom: () => void
    isStaff?: boolean
}

export function ChatPanel({id, title, isAtBottom, scrollToBottom, isStaff}: ChatPanelProps) {
    const {
        messages,
        submitUserMessage,
    } = useMessages();

    const navigate = useNavigate();

    // const [shareDialogOpen, setShareDialogOpen] = React.useState(false)

    const config = getAgoConfig();

    const exampleMessages = config.exampleMessages;

    return (
        <div
            className="fixed inset-x-0 bottom-0 w-full lg:min-w-[758px] bg-transparent duration-300 ease-in-out animate-in peer-[[data-state=open]]:group-[]:lg:pl-[250px] peer-[[data-state=open]]:group-[]:xl:pl-[300px]"
        >
            <ButtonScrollToBottom
                isAtBottom={isAtBottom}
                scrollToBottom={scrollToBottom}
            />

            <div className="mx-auto max-w-3xl px-4">
                <div className="mb-4 grid grid-cols-2 gap-2 px-4 sm:px-0">
                    {messages.length === 0 &&
                        exampleMessages.map((example, index) => (
                            <button
                                key={example.heading}
                                className={`relative cursor-pointer rounded-lg border bg-white p-4 hover:bg-zinc-50 dark:bg-zinc-950 dark:hover:bg-zinc-900 ${
                                    index > 1 && 'hidden md:block'
                                }`}
                                onClick={async () => {
                                    await submitUserMessage(example.message, navigate, isStaff);
                                }}
                            >
                                <div className="relative">
                                    <div className="text-left  font-semibold">{example.heading}</div>
                                    <div className="text-left  text-zinc-600">
                                        {example.subheading}
                                    </div>
                                </div>
                                <span className="material-symbols-outlined text-blue-900 absolute top-2 right-2">
                    arrow_forward
                </span>
                            </button>
                        ))}
                </div>


                {messages?.length >= 2 ? (
                    <div className="flex h-12 items-center justify-center">
                        <div className="flex space-x-2">
                            {id && title ? (
                                <>
                                    {/*<Button*/}
                                    {/*    variant="outline"*/}
                                    {/*    onClick={() => setShareDialogOpen(true)}*/}
                                    {/*>*/}
                                    {/*    <IconShare className="mr-2"/>*/}
                                    {/*    Share*/}
                                    {/*</Button>*/}
                                    {/*<ChatShareDialog*/}
                                    {/*  open={shareDialogOpen}*/}
                                    {/*  onOpenChange={setShareDialogOpen}*/}
                                    {/*  onCopy={() => setShareDialogOpen(false)}*/}
                                    {/*  shareChat={shareChat}*/}
                                    {/*  chat={{*/}
                                    {/*    id,*/}
                                    {/*    title,*/}
                                    {/*    messages: messages*/}
                                    {/*  }}*/}
                                    {/*/>*/}
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : null}

                <PromptForm isStaff={isStaff}/>
                <FooterText className="hidden sm:block"/>
            </div>
        </div>
    )
}
