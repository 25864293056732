import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {postSimilaritySearch} from "../../service/admin/adminDebugSimilaritySearchService.ts";
import {formatSimilaritySearch} from "../../service/similarity-search/FormatSimilaritySearchService.ts";
import {JsonSource} from "../../models/knowledgeBaseModel.ts";

export default function AdminDebugSimilaritySearchPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<{
        id: string,
        source: JsonSource;
        distance: number,
        relatedQuestions: boolean
    }[]>([]);
    const navigate = useNavigate();

    const fetchResult = async (query: string) => {
        setLoading(true);
        setError(null);
        setResults([]);
        try {
            const fetchedResults = await postSimilaritySearch(query, navigate);
            const formattedResults = formatSimilaritySearch(fetchedResults)
            setResults(formattedResults);
        } catch (err) {
            setError("Failed to fetch the message. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        fetchResult(query);
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <form onSubmit={handleSubmit} className="mb-4">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="p-2 border border-gray-300 rounded mr-2"
                    placeholder="Enter your query"
                />
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                    Submit
                </button>
            </form>

            {loading && <div className="flex justify-center items-center">Loading...</div>}

            {error && <div className="flex justify-center items-center text-red-500">{error}</div>}

            {results.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold">Results:</h3>
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">#</th>
                            <th className="py-2 px-4 border-b">Title</th>
                            <th className="py-2 px-4 border-b">Distance</th>
                            <th className="py-2 px-4 border-b">Origin</th>
                            <th className="py-2 px-4 border-b">ID</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.map((result, index) => (
                            <tr
                                key={index}
                            >
                                <td className="py-2 px-4">{index + 1}</td>
                                <td className="py-2 px-4">{result?.source?.title}</td>
                                <td className="py-2 px-4">{result.distance}</td>
                                <td className="py-2 px-4">
                                    {result.source.name}
                                    {result.relatedQuestions && (
                                        <span
                                            className="ml-2 bg-blue-100 text-blue-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">
                                    Related Questions
                                </span>
                                    )}
                                </td>
                                <td className="py-2 px-4">
                                    <Link to={'/knowledge-base/?source_id=' + result.id}>{result.id}</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
