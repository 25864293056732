import {useEffect, useState} from 'react';
import {ExclamationTriangleIcon} from '@radix-ui/react-icons';
import {FaRegMeh, FaThumbsDown, FaThumbsUp} from 'react-icons/fa';
import {User} from '../lib/types';
import {Link, useNavigate} from "react-router-dom";
import {Separator} from './ui/separator';
import {BotMessage, StaffMessage, UserMessage} from './message';
import {AGOMessage, MessageThread, MessageThreadEvaluation} from "../models/messageModel.ts";
import {getUser} from "../service/UserService.ts";
import {useMessages} from "../context/messagesContext.tsx";
import {spinner} from "./spinner.tsx";
import {Button} from "./ui/button.tsx";
import {evaluateCommentThread, evaluateThread} from "../service/admin/adminChatThreadService.ts";
import {cn} from "../lib/utils.ts";
import SimpleMarkdown from "./SimpleMarkdown.tsx";
import {transformMarkdownImageURLService} from "../service/markdown/transformMarkdownImageURLService.ts";


export interface ChatList {
    messages: AGOMessage[]
    user?: User
    isShared: boolean
    isStaff?: boolean
    thread?: MessageThread
}

const displayMessage = (message: AGOMessage, isStaff = false) => {
    switch (message.role) {
        case 'user':
            return <UserMessage message={message}/>
        case 'assistant':
            return <BotMessage message={message}/>
        case 'Staff':
            return <StaffMessage isStaff={isStaff} message={message}/>
        default:
            return null
    }
}

export function ChatList({messages, isStaff, isShared, thread}: ChatList) {
    const {isLoadingMessage, setThread} = useMessages();
    const navigate = useNavigate();
    const [comment, setComment] = useState(thread?.evaluation_comment ?? '');
    const [category, setCategory] = useState(thread?.evaluation_category ?? '');
    const [isEditing, setIsEditing] = useState(false);

    if (!messages.length) {
        return null;
    }

    useEffect(() => {
        setComment(thread?.evaluation_comment ?? '');
        setCategory(thread?.evaluation_category ?? '');
    }, [thread, isEditing]);

    const user = getUser();

    const updateEvaluation = async (evaluation: MessageThreadEvaluation): Promise<void> => {
        if (!thread) {
            return;
        }

        await evaluateThread(thread.id, evaluation, navigate);
        setThread({...thread, evaluation});
    }

    const sendComment = async (): Promise<void> => {
        if (!thread) {
            return;
        }

        await evaluateCommentThread(thread.id, comment, category, navigate);
        setThread({...thread, evaluation_comment: comment, evaluation_category: category});
        setIsEditing(false);
    }

    return (
        <div className="relative mx-auto max-w-2xl px-4">
            {!isShared && !user ? (
                <>
                    <div className="group relative mb-4 flex items-start md:-ml-12">
                        <div
                            className="bg-background flex size-[25px] shrink-0 select-none items-center justify-center rounded-md border shadow-sm">
                            <ExclamationTriangleIcon/>
                        </div>
                        <div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
                            <p className="text-muted-foreground leading-normal">
                                Please{' '}
                                <Link to="/login" className="underline">
                                    log in
                                </Link>{' '}
                                to save and revisit your chat history!
                            </p>
                        </div>
                    </div>
                    <Separator className="my-4"/>
                </>
            ) : null}

            {messages.map((message, index) => (
                <div key={message.id}>
                    {displayMessage(message, isStaff)}
                    {index < messages.length - 1 && <Separator className="my-4"/>}
                </div>
            ))}

            {isLoadingMessage && spinner}

            {isStaff && (
                <>
                    <Separator className="my-4"/>
                    <h1 className="mb-1 text-lg font-semibold">Evaluation by the team:</h1>
                    <div className="flex w-full justify-between space-x-4">
                        <Button
                            variant="ghost"
                            onClick={() => updateEvaluation('KO')}
                            className={cn(
                                "flex items-center rounded-lg h-12 text-black px-4 py-2 w-1/4 justify-center transition-transform duration-200",
                                thread?.evaluation === 'KO'
                                    ? "bg-red-600 ring-2 ring-black transform scale-105 shadow-lg"
                                    : "bg-red-300 hover:bg-red-500 hover:scale-105"
                            )}
                        >
                            <FaThumbsDown className="mr-2"/>Bad
                        </Button>
                        <Button
                            variant="ghost"
                            onClick={() => updateEvaluation('NEUTRAL')}
                            className={cn(
                                "flex items-center rounded-lg h-12 text-black px-4 py-2 w-1/4 justify-center transition-transform duration-200",
                                thread?.evaluation === 'NEUTRAL'
                                    ? "bg-yellow-600 ring-2 ring-black transform scale-105 shadow-lg"
                                    : "bg-yellow-300 hover:bg-yellow-500 hover:scale-105"
                            )}
                        >
                            <FaRegMeh className="mr-2"/>Neutral
                        </Button>
                        <Button
                            variant="ghost"
                            onClick={() => updateEvaluation('OK')}
                            className={cn(
                                "flex items-center rounded-lg h-12 text-black px-4 py-2 w-1/4 justify-center transition-transform duration-200",
                                thread?.evaluation === 'OK'
                                    ? "bg-green-600 ring-2 ring-black transform scale-105 shadow-lg"
                                    : "bg-green-300 hover:bg-green-500 hover:scale-105"
                            )}
                        >
                            <FaThumbsUp className="mr-2"/>Good
                        </Button>
                        <Button
                            variant="ghost"
                            onClick={() => setIsEditing(!isEditing)}
                            className="flex items-center rounded-lg h-12 px-4 py-2 w-1/4 justify-center bg-blue-600 text-white hover:bg-blue-800 hover:text-white"
                        >
                            {isEditing ? 'Cancel' : 'Add/Edit Comment'}
                        </Button>
                    </div>

                    {isEditing ? (
                        <div className="mt-4">
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Add a comment..."
                                className="w-full border rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                                rows={4}
                            />
                            <input
                                type="text"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                placeholder="Add a category..."
                                className="mt-2 w-full border rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                            />
                            <Button
                                variant="ghost"
                                onClick={sendComment}
                                className={cn(
                                    "mt-2 w-full flex items-center justify-center rounded-lg h-12 bg-blue-500 text-white hover:bg-blue-800 transition duration-200 ease-in-out transform hover:scale-105"
                                )}
                            >
                                Submit Evaluation
                            </Button>
                        </div>
                    ) : (
                        <div
                            className="mt-4 p-4 bg-gray-100 rounded-lg shadow-md cursor-pointer"
                            onDoubleClick={() => setIsEditing(true)}
                        >
                            <SimpleMarkdown
                                content={transformMarkdownImageURLService(comment || 'No comment added yet.')}/>
                            {category && (
                                <p className="mt-2 text-gray-600">Category: {category}</p>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}