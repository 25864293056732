import React, {useEffect, useState} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons";

type PaginationProps = {
    totalResults: number;
    resultsPerPage: number;
    onPageChange: (page: number, previousPage: number) => void;
    forceCurrentPage?: number;
};

const Pagination: React.FC<PaginationProps> = ({totalResults, resultsPerPage, onPageChange, forceCurrentPage}) => {
    const [currentPage, setCurrentPage] = useState<number>(forceCurrentPage || 1);
    const totalPages = Math.ceil(totalResults / resultsPerPage);

    useEffect(() => {
        if (forceCurrentPage && forceCurrentPage !== currentPage) {
            setCurrentPage(forceCurrentPage);
        }
    }, [forceCurrentPage, currentPage]);

    const changePage = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            onPageChange(newPage, currentPage);
        }
    };

    const renderPageNumbers = () => {
        let pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`relative inline-flex items-center px-4 py-2 font-semibold ${
                            currentPage === i ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                        }`}
                        onClick={() => changePage(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            pages.push(
                <button
                    key={1}
                    className={`relative inline-flex items-center px-4 py-2 font-semibold ${
                        currentPage === 1 ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                    }`}
                    onClick={() => changePage(1)}
                >
                    1
                </button>
            );
            if (startPage > 2) pages.push(<span key="start-ellipsis" className="px-4 py-2">...</span>);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`relative inline-flex items-center px-4 py-2 font-semibold ${
                            currentPage === i ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                        }`}
                        onClick={() => changePage(i)}
                    >
                        {i}
                    </button>
                );
            }
            if (endPage < totalPages - 1) pages.push(<span key="end-ellipsis" className="px-4 py-2">...</span>);

            pages.push(
                <button
                    key={totalPages}
                    className={`relative inline-flex items-center px-4 py-2 font-semibold ${
                        currentPage === totalPages ? 'bg-indigo-600 text-white' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                    }`}
                    onClick={() => changePage(totalPages)}
                >
                    {totalPages}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 mt-1">
            <div className=" text-gray-700">
                Showing <span className="font-medium">{resultsPerPage * (currentPage - 1) + 1}</span> to{' '}
                <span className="font-medium">{Math.min(resultsPerPage * currentPage, totalResults)}</span> of{' '}
                <span className="font-medium">{totalResults}</span> results
            </div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                    onClick={() => changePage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon aria-hidden="true" className="h-5 w-5"/>
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => changePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon aria-hidden="true" className="h-5 w-5"/>
                </button>
            </nav>
        </div>
    );
};

export default Pagination;