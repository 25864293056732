import React, {Suspense, useEffect, useState} from 'react';
import {ChevronDownIcon} from "@radix-ui/react-icons";
import * as Accordion from '@radix-ui/react-accordion';
import {
    createNewKnowledgeChangeFromForm,
    deleteKnowledgeChange,
    updateKnowledgeChangeStatus
} from "../../service/admin/adminKnowledgeManagementService.ts";
import {useNavigate} from "react-router-dom";
import {spinner} from "../spinner.tsx";
import {getCollectionsName} from "../../service/ConfigService.ts";
import {AGOKnowledgeDocument, CRUDAGOKnowledgeDocument} from "../../models/knowledgeBaseModel.ts";

const ImprovedMarkdown = React.lazy(() => import('../ImprovedMarkdown.tsx'));

const collections = getCollectionsName(); // Assuming getCollectionsName() returns an array of collection names

interface KnowledgeBaseChangeListProps {
    knowledge_changes: AGOKnowledgeDocument[];
}

const KnowledgeBaseChangeList: React.FC<KnowledgeBaseChangeListProps> = ({knowledge_changes}) => {
    const [changes, setChanges] = useState<AGOKnowledgeDocument[]>(knowledge_changes);
    const [loading, setLoading] = useState(false);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editedContent, setEditedContent] = useState<string>('');
    const [editedCollection, setEditedCollection] = useState<string>('');
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newEntryTitle, setNewEntryTitle] = useState('');
    const [newEntryContent, setNewEntryContent] = useState('');
    const [newEntryCollection, setNewEntryCollection] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setChanges(knowledge_changes);
    }, [knowledge_changes]);

    const handleUpdateStatus = async (id: string, status: 'validated' | 'rejected') => {
        setLoading(true);
        try {
            const knowledgeChange = {
                id: id,
                status: status
            }
            const updatedChange = await updateKnowledgeChangeStatus(knowledgeChange, navigate);
            setChanges(prevChanges =>
                prevChanges.map(change =>
                    change.id === id ? updatedChange : change
                )
            );
        } catch (error) {
            console.error(`Failed to update status for change ${id}:`, error);
        } finally {
            setLoading(false);
        }
    };

    const onValidate = (id: string) => handleUpdateStatus(id, 'validated');
    const onReject = (id: string) => handleUpdateStatus(id, 'rejected');

    const handleEdit = (id: string, content: string, collection: string) => {
        setEditingId(id);
        setEditedContent(content);
        setEditedCollection(collection);
    };

    const handleSave = async (id: string) => {
        const knowledgeChange = {
            id: id,
            content: editedContent,
            collection_name: editedCollection
        }
        const updatedChange = await updateKnowledgeChangeStatus(knowledgeChange, navigate);
        setChanges(prevChanges =>
            prevChanges.map(change =>
                change.id === id ? updatedChange : change
            )
        );
        setEditingId(null);
    };

    const handleCancel = () => {
        setEditingId(null);
    };

    const handleAddNewEntry = () => {
        setIsAddingNew(true);
    };

    const handleSaveNewEntry = async () => {
        if (!newEntryCollection) {
            alert('Please select a collection');
            return;
        }
        const newEntry: CRUDAGOKnowledgeDocument = {
            title: newEntryTitle,
            content: newEntryContent,
            collection_name: newEntryCollection,
            status: 'created',
        };
        const newChange = await createNewKnowledgeChangeFromForm(newEntry, navigate);
        if (!newChange) {
            return;
        }

        setChanges([newChange, ...changes]);
        setIsAddingNew(false);
        setNewEntryTitle('');
        setNewEntryContent('');
        setNewEntryCollection('');
    };

    const handleCancelNewEntry = () => {
        setIsAddingNew(false);
        setNewEntryTitle('');
        setNewEntryContent('');
        setNewEntryCollection('');
    };

    const handleDelete = async (id: string) => {
        setLoading(true);
        try {
            await deleteKnowledgeChange(id, navigate);
            setChanges(prevChanges => prevChanges.filter(change => change.id !== id));
        } catch (error) {
            console.error(`Failed to delete change ${id}:`, error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button
                onClick={handleAddNewEntry}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
            >
                Add a New Entry
            </button>

            {isAddingNew && (
                <div className="bg-white rounded-lg shadow-md border border-gray-200 p-4 mb-4">
                    <div className="mb-2">
                        <label className="block text-gray-700 font-semibold mb-2">Title:</label>
                        <input
                            type="text"
                            value={newEntryTitle}
                            onChange={(e) => setNewEntryTitle(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700 font-semibold mb-2">Content:</label>
                        <textarea
                            value={newEntryContent}
                            onChange={(e) => setNewEntryContent(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            rows={6}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700 font-semibold mb-2">Collection:</label>
                        <select
                            value={newEntryCollection}
                            onChange={(e) => setNewEntryCollection(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select a collection</option>
                            {collections.map((collection) => (
                                <option key={collection} value={collection}>{collection}</option>
                            ))}
                        </select>
                    </div>
                    <div className="flex space-x-4 mt-4">
                        <button
                            onClick={handleSaveNewEntry}
                            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancelNewEntry}
                            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 focus:outline-none"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            <Accordion.Root type="multiple" className="space-y-4">
                {changes.map((change) => (
                    <Accordion.Item
                        key={change.id}
                        value={change.id}
                        className="bg-white rounded-lg shadow-md border border-gray-200"
                    >
                        <Accordion.Header className="flex">
                            <Accordion.Trigger
                                className="flex w-full justify-between p-4 text-left text-gray-800 font-semibold text-lg focus:outline-none"
                            >
                                <span>{change.title}</span>
                                <ChevronDownIcon className="w-5 h-5 text-gray-500"/>
                            </Accordion.Trigger>
                        </Accordion.Header>
                        <Accordion.Content className="px-4 pb-4 text-gray-600">
                            <div className="mb-2">
                                <strong>Content:</strong>
                                {editingId === change.id ? (
                                    <textarea
                                        value={editedContent}
                                        onChange={(e) => setEditedContent(e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        rows={6}
                                    />
                                ) : (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ImprovedMarkdown content={change.content}/>
                                    </Suspense>
                                )}
                            </div>
                            <div className="mb-2">
                                <strong>Collection:</strong> {editingId === change.id ? (
                                <select
                                    value={editedCollection}
                                    onChange={(e) => setEditedCollection(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="">Select a collection</option>
                                    {collections.map((collection) => (
                                        <option key={collection} value={collection}>{collection}</option>
                                    ))}
                                </select>
                            ) : (
                                <span>{change.collection_name}</span>
                            )}
                            </div>
                            <div className="mb-2">
                                <strong>Status:</strong> <span
                                className={`inline-block px-2 py-1 rounded-full text-sm font-medium ${
                                    change.status === 'created'
                                        ? 'bg-yellow-100 text-yellow-800'
                                        : (change.status === 'validated' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')
                                }`}>
                                    {change.status}
                                </span>
                            </div>
                            <div className="text-gray-500">
                                <strong>Created At:</strong> {new Date(change.created_at).toLocaleString()}
                            </div>
                            {editingId !== change.id && (
                                <div className="mt-4 flex space-x-4">
                                    {loading ? spinner : (
                                        <>
                                            <button
                                                onClick={() => onValidate(change.id)}
                                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none"
                                            >
                                                Validate
                                            </button>
                                            <button
                                                onClick={() => onReject(change.id)}
                                                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
                                            >
                                                Reject
                                            </button>
                                            <button
                                                onClick={() => handleEdit(change.id, change.content, change.collection_name)}
                                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleDelete(change.id)}
                                                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 focus:outline-none"
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                            {editingId === change.id && (
                                <div className="mt-4 flex space-x-4">
                                    <button
                                        onClick={() => handleSave(change.id)}
                                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleCancel}
                                        className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 focus:outline-none"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            )}
                        </Accordion.Content>
                    </Accordion.Item>
                ))}
            </Accordion.Root>
        </div>
    );
};

export default KnowledgeBaseChangeList;
