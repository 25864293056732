import {getToken} from "./UserService.ts";
import {NavigateFunction} from "react-router-dom";
import {toast} from "sonner";
import {eraseCSRFToken, getCSRFToken, loginFlow, setCSRFToken} from "./AuthService.ts";

export const getApiBaseUrl = () => {

    let apiBaseUrl = 'VITE_API_BASE_URL_PLACEHOLDER';

    const apiBaseUrlEnv = import.meta.env.VITE_API_BASE_URL;

// If the API base URL contains the placeholder, replace it with the actual value from the environment
// Otherwise it means that we are running in production in a docker container
    if (apiBaseUrl.indexOf('PLACEHOLDER') !== -1) {
        apiBaseUrl = apiBaseUrlEnv;
    }

    return apiBaseUrl;
}

const fetchWithAuth = async (url: string, options: RequestInit = {}) => {
    const token = getToken()
    const apiBaseUrl = getApiBaseUrl()

    if (token) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`
        }
    }

    options.credentials = 'include'

    if (options.method === 'POST' || options.method === 'PUT' || options.method === 'DELETE') {
        let csrftoken = getCSRFToken();

        if (!csrftoken) {
            const options2 = {
                ...options,
                method: 'GET',
            }
            delete options2.body
            const response = await fetch(`${apiBaseUrl}/csrf`, options2)

            if (response.ok) {
                const body = await response.json();
                if (!body?.token) {
                    throw new Error('CSRF token not found in response');
                }
                setCSRFToken(body.token);
                csrftoken = body.token;
            } else {
                throw new Error('Failed to fetch CSRF token');
            }
        }

        options.headers = {...options.headers, 'X-CSRFToken': csrftoken ?? ''}
    }
    const completeUrl = `${apiBaseUrl}${url}`

    return fetch(completeUrl, options)
}

export default fetchWithAuth

export async function getJsonAndHandleError(response: Response, navigate: NavigateFunction) {
    const jsonResponse = await response.json();

    if (!response.ok) {
        toast.error(jsonResponse.message || response.statusText);

        if (response.status == 401 || response.status == 403) {
            eraseCSRFToken();
            await loginFlow(navigate);
        }
    }

    return jsonResponse
}

