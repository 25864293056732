import {cn} from "../lib/utils"
import {spinner} from "./spinner"
import {Button} from "./ui/button.tsx";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {ReactToMessageNegatively, ReactToMessagePositively,} from "../service/MessageService.ts";
import {createNewKnowledgeChange} from "../service/admin/adminKnowledgeManagementService.ts";
import {toast} from "sonner";
import {AGOMessage} from "../models/messageModel.ts";
import {ReaderIcon} from "@radix-ui/react-icons";
import {getAgoConfig} from "../service/ConfigService.ts";
import {InitialsIcon, InitialsStaffIcon} from "./ui/initials.tsx";
import {isStaff} from "../service/UserService.ts";
import {transformMarkdownImageURLService} from "../service/markdown/transformMarkdownImageURLService.ts";

const ImprovedMarkdown = React.lazy(() => import('./ImprovedMarkdown.tsx'));

export function UserMessage({message}: { message: AGOMessage }) {
    const email = message.from_user?.email ?? '';
    return (
        <div className="group relative flex items-start md:-ml-12">
            <InitialsIcon email={email}/>
            <div className="ml-4 flex-1 space-y-2 overflow-hidden pl-2">
                {message.content}
            </div>
            {isStaff() && (
                <div className="flex items-center gap-2 py-2">
                    <Button
                        variant="ghost"
                        size="icon"
                        className={`w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900`}
                    >
                        <Link to={"/admin/similarity-search-message/" + message.id}>
                            <span className="material-symbols-outlined text-[1.125rem]">search_insights</span>
                            <span className="sr-only">search insights</span>
                        </Link>
                    </Button>
                </div>
            )}
        </div>
    )
}

export function StaffMessage({message, isStaff}: {
    isStaff: boolean,
    message: AGOMessage
}) {

    const idMessage = message.id
    const content = message.content
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const email = message.from_user?.email ?? '';
    const postCreateChange = async () => {
        try {
            setLoading(true);
            await createNewKnowledgeChange(idMessage, navigate);
            toast.success('Knowledge change created successfully');
        } catch (err) {
            toast.error('Failed to create the knowledge change. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={`group relative flex items-start md:-ml-12`}
        >
            <InitialsStaffIcon email={email}/>
            <div className="ml-4 flex-1 space-y-2 overflow-hidden pl-2">
                <div><ImprovedMarkdown content={content} raw={false}/></div>
                {isStaff && (
                    <div className="flex items-center gap-2 py-2">
                        <Button
                            variant="ghost"
                            size="icon"
                            className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            onClick={() => postCreateChange()}
                        >
                            <div className="flex flex-row items-center gap-x-2">
                                {loading ? spinner : (
                                    <span className="material-symbols-outlined text-[1.125rem]">add</span>)}
                            </div>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

function removeSupTags(input: string): string {
    return input.replace(/<sup[^>]*>.*?<\/sup>/g, '');
}

export function BotMessage({message, className}: Readonly<{
    className?: string,
    message: AGOMessage
}>) {

    const content = message.content;
    const message_id = message.id;
    const sources = message.knowledge_sources;
    const ask_talk_human = message.ask_to_talk_to_human;
    const allowedToCreateTickets = message.allowed_to_create_ticket;
    // const messageIfNotAllowed = message.message_if_not_allowed;
    const reactionPositive = message.reaction ?? false;
    const reactionNegative = message.reaction == false;
    const config = getAgoConfig();

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(removeSupTags(content)).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // reset after 2 seconds
        });
    };
    const navigate = useNavigate();
    const transformedMarkdown = transformMarkdownImageURLService(content);


    return (
        <div className={cn('group relative flex items-start md:-ml-12', className)}>
            <img
                src="/sparkle.svg"
                alt="Bot"
                className={`material-symbols-outlined rounded-full border-2 ${ask_talk_human ? 'border-purple-300' : 'border-[#6683cf]'} ${ask_talk_human ? 'bg-purple-500' : 'bg-blue-500'}`}
                style={{width: '32px', height: '32px', padding: '4px'}}
            />

            <div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
                {!(ask_talk_human && !allowedToCreateTickets) && (
                    <ImprovedMarkdown content={transformedMarkdown} raw={true}/>)}
                <div className="mt-4 grid grid-cols-4 gap-4">
                    {!ask_talk_human && sources && Object.entries(sources).map(([key, value]) => (
                        <div
                            key={key}
                            id={`citation-${value.position}`}
                            className="flex items-center px-2 py-1 border rounded-md hover:bg-blue-50"
                        >
                            <Link to={'/knowledge-base/?source_id=' + value.knowledge_document.id}
                                  className="flex flex-col items-start">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center">
                                        <div
                                            className="text-sm bg-sky-600 text-sky-950 rounded-full w-3 h-3 flex items-center justify-center">
                                            {value.position}
                                        </div>
                                        <ReaderIcon className="w-4 h-4 ml-2"/>
                                    </div>
                                </div>
                                <div className="mt-1 w-32 text-sm line-clamp-3"
                                     title={value.knowledge_document.title}>
                                    {value.knowledge_document.title}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>

                {ask_talk_human ? (
                    <div className="flex items-center">
                        <Link to={config.humanSupportLink}>
                            <Button
                                variant="ghost"
                                className="bg-purple-400 hover:bg-purple-600"
                            >Contact Human support <span
                                className="material-symbols-outlined text-[1.125rem]">face</span>
                            </Button>
                        </Link>
                    </div>
                ) : (
                    <div className="flex items-center gap-2 py-2">
                        <Button
                            variant="ghost"
                            size="icon"
                            className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            onClick={handleCopy}
                        >
                            {copied ? <span className="material-symbols-outlined text-[1.125rem]">check</span> :
                                <span className="material-symbols-outlined text-[1.125rem]">content_copy</span>}
                            <span className="sr-only">Copy</span>
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            className={`w-4 h-4 hover:bg-transparent ${reactionPositive ? "text-stone-900" : "text-stone-400"} hover:text-stone-900`}
                            onClick={() => {
                                ReactToMessagePositively(message_id, navigate);
                                message.reaction = true
                            }}
                        >
                            <span className="material-symbols-outlined text-[1.125rem]">thumb_up</span>
                            <span className="sr-only">Upvote</span>
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            className={`w-4 h-4 hover:bg-transparent ${reactionNegative ? "text-stone-900" : "text-stone-400"} hover:text-stone-900`}
                            onClick={() => {
                                ReactToMessageNegatively(message_id, navigate);
                                message.reaction = false
                            }}
                        >
                            <span className="material-symbols-outlined text-[1.125rem]">thumb_down</span>
                            <span className="sr-only">Downvote</span>
                        </Button>
                        {/*<Button*/}
                        {/*    variant="ghost"*/}
                        {/*    size="icon"*/}
                        {/*    className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"*/}
                        {/*>*/}
                        {/*    <RefreshCcwIcon className="w-4 h-4"/>*/}
                        {/*    <span className="sr-only">Regenerate</span>*/}
                        {/*</Button>*/}
                    </div>
                )}
            </div>
        </div>
    )
}
