import {AgoConfig} from "../models/configType.ts";
import fetchWithAuth, {getJsonAndHandleError} from "./fetchWithAuth.ts";
import {NavigateFunction} from "react-router-dom";

export const configs = {
    build: {
        title: "Build value",
        fontColor: "#102B49",
        auth: {
            reverseProxy: false
        },
        logo: 'Build value',
        favicon: 'Build value',
        humanSupportLink: 'Build value',
        exampleMessages: [],
        collection_name: [],
    },
    ago: {
        title: "Hi, I'm AGO! How can I assist you today?",
        subtitle: "",
        fontColor: "#102B49",
        auth: {
            reverseProxy: false
        },
        logo: '/ago-logo.png',
        favicon: '/ago-favicon.ico',
        humanSupportLink: '',
        emailDomainName: 'useago.com',
        exampleMessages: [
            {
                heading: 'Customer of AGO',
                subheading: 'What are the different customer of AGO?',
                message: `What are the different customer of AGO?`
            },
            {
                heading: 'Knowledge management',
                subheading: 'How AGO will help my team with knowledge management?',
                message: 'How AGO will help my team with knowledge management?'
            },
        ],
        collection_name: ['ago'],
    },
    mirakl: {
        title: "Hi, you're speaking with your Mirakl AI Agent.\n" +
            "Tell me as much as you can about your question.",
        subtitle: "Beta version",
        fontColor: "#102B49",
        auth: {
            reverseProxy: true
        },
        logo: '/mirakl-logo.png',
        favicon: '/mirakl-favicon.png',
        humanSupportLink: 'https://bit.ly/4eSh99c',
        emailDomainName: 'mirakl.com',
        collection_name: ['seller', 'operator'],
        exampleMessages: [
            // {
            //     heading: 'Integrating your Marketplace',
            //     subheading: 'Procedures to connect your systems to Mirakl',
            //     message: `What are the procedures to connect my system to Mirakl?`
            // },
            // {
            //     heading: 'Configuring my Mirakl',
            //     subheading: 'This section explains how to configure your Mirakl platform',
            //     message: 'What are the steps to configure my Mirakl platform?'
            // },
            // {
            //     heading: 'Marketplace Key concepts',
            //     subheading: 'Learn more about the key concepts of Mirakl marketplaces',
            //     message: `What are the key concepts of Mirakl marketplaces?`
            // },
            // {
            //     heading: 'Enterprise Marketplace Seller Report',
            //     subheading: `Analyze seller activity on Mirakl-powered Marketplaces across the globe`,
            //     message: `How can I analyze seller activity on Mirakl-powered Marketplaces?`
            // }
        ]
    }
}


export const getAgoConfig = (): AgoConfig => {
    let viteCustomer = 'VITE_CUSTOMER_PLACEHOLDER';

    let viteBaseCustomer = '';

    try {
        // ⚠️ let this for the build process
        // @ts-ignore .env is not defined during the build process
        viteBaseCustomer = import.meta.env.VITE_AGO_CUSTOMER_ID ?? 'ago';
    } catch (e) {
        // We are in the build process
        viteBaseCustomer = 'build';
    }

    // If viteConfig contains the placeholder, replace it with the actual value from the environment
    // Otherwise it means that we are running in production in a docker container
    if (viteCustomer.includes('PLACEHOLDER')) {
        viteCustomer = viteBaseCustomer;
    }

    // @ts-expect-error VITE_CUSTOMER_PLACEHOLDER is changed when the docker image is run
    return configs[viteCustomer];
}


export const getLogo = () => {
    return getAgoConfig().logo;
}

export const getReverseProxy = () => {
    return getAgoConfig().auth.reverseProxy;
}

export const getCollectionsName = () => {
    return getAgoConfig().collection_name;
}

export const getConfigFromPermission = async (navigate: NavigateFunction) => {
    try {
        const response = await fetchWithAuth(`/api/config/config-by-permissions `, {
            method: 'GET',
        })
        return await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}
