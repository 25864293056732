import {useMessages} from "../context/messagesContext.tsx";
import {useNavigate, useParams} from "react-router-dom";
import {getChatMessagesFromThread} from "../service/ChatService.ts";
import {useEffect} from 'react';
import {Chat} from "../components/chat.tsx";

export default function ChatThreadPage() {
    const {thread_id} = useParams();
    const {setMessages, setThread} = useMessages();
    const navigation = useNavigate();

    useEffect(() => {
        const fetchMessages = async () => {
            const response = await getChatMessagesFromThread(thread_id, navigation);
            setMessages(response.messages || []);
            setThread(response);
        };

        fetchMessages().then();
    }, [thread_id]);

    return (
        <Chat isStaff={false}/>
    );
}
