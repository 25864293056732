import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";
import {AGOKnowledgeDocument, CRUDAGOKnowledgeDocument} from "../../models/knowledgeBaseModel.ts";

export async function createNewKnowledgeChange(id: string, navigate: NavigateFunction) {
    const body = {id: id}
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/create-new-knowledge-change', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function createNewKnowledgeChangeFromForm(knowledgeBaseChange: CRUDAGOKnowledgeDocument, navigate: NavigateFunction): Promise<AGOKnowledgeDocument | void> {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/create-new-knowledge-change-from-form', {
            method: 'POST',
            body: JSON.stringify(knowledgeBaseChange),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function getAllKnowledgeChange(navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/all-knowledge-change', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function updateKnowledgeChangeStatus(knowledgeBaseChange: CRUDAGOKnowledgeDocument, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/update-knowledge-change', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(knowledgeBaseChange)
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function deleteKnowledgeChange(id: string, navigate: NavigateFunction) {
    try {
        await fetchWithAuth('/api/knowledge_management/sync/delete-knowledge-change', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: id})
        })
    } catch (error) {
        navigate('/')
    }
}

export async function overrideKnowledgeContent(knowledgeBaseChange: CRUDAGOKnowledgeDocument, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/override-content', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(knowledgeBaseChange)
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function deleteOverrideKnowledgeContent(id: string, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/delete-override-content', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: id})
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function updateKnowledgeRelatedQuestions(knowledgeBaseChange: CRUDAGOKnowledgeDocument, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/related-questions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(knowledgeBaseChange)
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function deleteRelatedQuestionsKnowledgeContent(id: string, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/related-questions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: id, related_questions: ''})
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function updateExcludedFromSource(knowledgeBaseChange: CRUDAGOKnowledgeDocument, navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/excluded-from-source', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(knowledgeBaseChange)
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}
