import React, {createContext, ReactNode, useContext, useState} from 'react';
import {useChat} from "ai/react";
import {Chat} from "../lib/types.ts";
import {NavigateFunction} from "react-router-dom";
import {loadChats} from "../service/ChatService.ts";
import {nanoid} from "nanoid";
import fetchWithAuth from "../service/fetchWithAuth.ts";
import {toast} from "sonner";
import {AGOMessage, MessageThread, Source} from "../models/messageModel.ts";
import {getUser} from "../service/UserService.ts";

interface MessageContextType {
    messages: AGOMessage[];
    addMessage: (message: AGOMessage) => void;
    removeMessage: (index: number) => void;
    input: string;
    setInput: (value: string) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    thread: MessageThread | null; // Add thread here
    setThread: (value: MessageThread | null) => void;
    updateLastMessage: (value: string) => void;
    setMessages: (value: AGOMessage[]) => void;
    setChatThreads: (value: Chat[]) => void;
    chatThreads: Chat[];
    submitUserMessage: (value: string, navigate: NavigateFunction, isStaff: boolean | undefined) => Promise<void>;
    addSourcesLastMessage: (sources: Source[], id: string) => void;
    isLoadingMessage: boolean;
    setIsLoadingMessage: (value: boolean) => void;
}

const MessagesContext = createContext<MessageContextType | undefined>(undefined);

export const useMessages = (): MessageContextType => {
    const context = useContext(MessagesContext);
    if (!context) {
        throw new Error('useMessages must be used within a MessagesProvider');
    }
    return context;
};

interface MessagesProviderProps {
    children: ReactNode;
}

export const MessagesProvider: React.FC<MessagesProviderProps> = ({children}) => {
    const [chatThreads, setChatThreads] = useState<Chat[]>([])
    const [thread, setThread] = React.useState<MessageThread | null>(null);
    const [isLoadingMessage, setIsLoadingMessage] = React.useState<boolean>(false);


    const {input, setInput, handleInputChange} = useChat({
        keepLastMessageOnError: true,
    });
    const [messages, setMessages] = useState<AGOMessage[]>([]);

    const addMessage = (message: AGOMessage) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    const removeMessage = (index: number) => {
        setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
    };

    // We first need to add a message when receiving the response
    const updateLastMessage = (chunk: string) => {
        setMessages((prevMessages) => {
            const lastIndex = prevMessages.length - 1;
            const updatedMessages = [...prevMessages];
            updatedMessages[lastIndex] = {
                ...updatedMessages[lastIndex],
                content: updatedMessages[lastIndex].content + chunk
            };
            return updatedMessages;
        });
    };

    // message doesn't have a role here
    const setLastMessageAsAskHumanMessage = (message: AGOMessage) => {
        setMessages((prevMessages) => {
            const lastIndex = prevMessages.length - 1;
            const updatedMessages = [...prevMessages];
            updatedMessages[lastIndex] = {
                ...updatedMessages[lastIndex],
                ask_to_talk_to_human: true,
                allowed_to_create_ticket: message.allowed_to_create_ticket,
                message_if_not_allowed: message.message_if_not_allowed,
            };
            return updatedMessages;
        });
    };


    const addSourcesLastMessage = (knowledge_sources: Source[], id: string) => {
        setMessages((prevMessages) => {
            const lastIndex = prevMessages.length - 1;
            const updatedMessages = [...prevMessages];
            updatedMessages[lastIndex] = {
                ...updatedMessages[lastIndex],
                knowledge_sources: knowledge_sources,
                id: id,
            };
            return updatedMessages;
        });
    }

    const handleJsonResponseMessage = (jsonChunk: string, navigate: NavigateFunction) => {
        try {
            const json = JSON.parse(jsonChunk);
            if (json.content) {
                updateLastMessage(json.content);
                setThread(json.thread); // Save thread from the response
            }

            if (json.title) {
                loadChats(navigate)
                    .then(loadedChats => {
                        // Set the chats state with the loaded chats
                        setChatThreads(loadedChats)
                    })
                    .catch(error => {
                        // Handle any errors
                        console.error('Failed to load chats:', error)
                        setChatThreads([])
                    })

            }

            if (json.knowledge_sources) {
                addSourcesLastMessage(json.knowledge_sources, json.id);
            }

            if (json.ask_to_talk_to_human) {
                setLastMessageAsAskHumanMessage(json);
            }

        } catch (e) {
            console.error('Failed to parse JSON:', e);
        }
    }


    const submitUserMessage = async (value: string, navigate: NavigateFunction, isStaff: boolean | undefined = false) => {
        if (!value) return

        const user = getUser();

        if (isStaff) {
            addMessage(
                {
                    id: nanoid(),
                    content: value,
                    role: 'Staff',
                    from_user: {
                        id: user.id,
                        email: user.email,
                    }
                })
        } else {
            addMessage(
                {
                    id: nanoid(),
                    content: value,
                    role: 'user',
                    from_user: {
                        id: user.id,
                        email: user.email,
                    }
                })
        }


        let threadBody = null;
        if (thread) {
            threadBody = {
                id: thread.id,
            }
        }

        const prepareBody = {
            content: value,
            role: 'user',
            isStaff: isStaff,
            thread: threadBody ?? undefined,
            languages: navigator.languages,
        }
        setIsLoadingMessage(true);

        const response = await fetchWithAuth(`/api/business_messages/sync/handle-message-conversation`, {
            method: 'POST',
            body: JSON.stringify(prepareBody),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        setIsLoadingMessage(false);


        if (!response.ok) {
            if (response.status >= 400 && response.status < 500) {
                // Handle 400 range errors specifically
                const errorMessage = await response.json();
                toast.error(errorMessage.message || response.statusText);
                navigate('/login')
                throw new Error(`Client error: ${errorMessage.message || response.statusText}`);
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (isStaff) {
            return;
        }

        addMessage({
                id: '',
                role: 'assistant',
                content: '',
            }
        )

        if (!response.body) {
            return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let done = false;
        let buffer = '';

        while (!done) {
            const {value, done: readerDone} = await reader.read();
            done = readerDone;
            const chunkValue = decoder.decode(value, {stream: true});
            buffer += chunkValue;

            let boundary = buffer.indexOf('}{');
            while (boundary !== -1) {
                const jsonChunk = buffer.slice(0, boundary + 1);
                buffer = buffer.slice(boundary + 1);
                handleJsonResponseMessage(jsonChunk, navigate);

                boundary = buffer.indexOf('}{');
            }

            // Handle the remaining buffer if done
            if (done && buffer.length > 0) {
                handleJsonResponseMessage(buffer, navigate);
            }
        }
    }

    return (
        <MessagesContext.Provider
            value={{
                messages,
                addMessage,
                removeMessage,
                input,
                setInput,
                handleInputChange,
                thread,
                setThread,
                updateLastMessage,
                setMessages,
                setChatThreads,
                chatThreads,
                submitUserMessage,
                addSourcesLastMessage,
                isLoadingMessage,
                setIsLoadingMessage
            }}>
            {children}
        </MessagesContext.Provider>
    );
};
