import {getInitials} from "../../service/UserService.ts";


interface InitialsIconProps {
    email: string | null;
}

export function InitialsIcon({email}: InitialsIconProps) {
    return (
        <div
            className="flex items-center justify-center rounded-full bg-yellow border-2 border-[#f6db9b] text-white font-semibold"
            style={{width: '32px', height: '32px', fontSize: '12px'}}
        >
            {getInitials(email)}
        </div>
    );
}

export function InitialsStaffIcon({email}: InitialsIconProps) {
    return (
        <div
            className="flex items-center justify-center rounded-full bg-purple-600 border-2 border-purple-300 text-white font-semibold"
            style={{width: '32px', height: '32px', fontSize: '12px'}}
        >
            {getInitials(email)}
        </div>
    );
}

export function InitialsIcon24() {
    return (
        <div
            className="flex items-center justify-center rounded-full bg-yellow border-2 border-[#f6db9b] text-white font-semibold"
            style={{width: '24px', height: '24px', fontSize: '9px'}}
        >
            {getInitials()}
        </div>
    )
}
