import * as React from 'react'
import {Button} from './ui/button'
import {SidebarList} from './sidebar-list'
import {Link} from "react-router-dom";
import {useMessages} from "../context/messagesContext.tsx";
import {cn} from "../lib/utils.ts";
import {newChat} from "../service/NavigationService.ts";
import {useSidebar} from "../lib/hooks/use-sidebar.tsx";
import {SidebarToggle} from "./sidebar-toggle.tsx";
import {isStaff} from "../service/UserService.ts";
import {PATHS} from "../main.tsx";

export function ChatHistory() {
    const {setMessages, setThread} = useMessages();
    const {isSidebarOpen} = useSidebar();

    return (
        <div className="flex flex-col justify-between h-full p-3">
            <div className="flex flex-col items-center space-y-4">
                <Button
                    variant="ghost"
                    className={cn(
                        "flex items-center rounded-lg bg-white h-12 text-blue-900",
                        isSidebarOpen
                            ? "px-4 py-2 w-full justify-start"
                            : "w-12 justify-center",
                        "hover:bg-grey-300"
                    )}
                >
                    <Link
                        to="/"
                        className="flex items-center w-full"
                        onClick={() => {
                            newChat(setMessages, setThread);
                        }}
                    >
        <span
            className="material-symbols-outlined mr-2 text-[16px] leading-none">add_comment</span>
                        {isSidebarOpen && <span>Start conversation</span>}
                    </Link>
                </Button>

                {isStaff() && (
                    <>
                        <Button
                            variant="ghost"
                            className={cn(
                                "flex items-center rounded-lg bg-white h-12 text-blue-900",
                                isSidebarOpen
                                    ? "px-4 py-2 w-full justify-start"
                                    : "w-12 justify-center",
                                "hover:bg-grey-300"
                            )}
                        >
                            <Link to={'/admin/conversations'} className="flex items-center w-full">
                    <span
                        className="material-symbols-outlined mr-2 text-[16px] leading-none">history</span>
                                {isSidebarOpen && <span>Chat history</span>}
                            </Link>

                        </Button>
                        <Button
                            variant="ghost"
                            className={cn(
                                "flex items-center rounded-lg bg-white h-12 text-blue-900",
                                isSidebarOpen
                                    ? "px-4 py-2 w-full justify-start"
                                    : "w-12 justify-center",
                                "hover:bg-grey-300"
                            )}
                        >
                            <Link to={'/admin/knowledge-change-proposals'} className="flex items-center w-full">
                                <img src="/tips.svg" className="mr-2 text-[16px] leading-none text-black"
                                     alt="AI change proposals"/>
                                {isSidebarOpen && <span>Doc updates</span>}
                            </Link>
                        </Button>
                        <Button
                            variant="ghost"
                            className={cn(
                                "flex items-center rounded-lg bg-white h-12 text-blue-900",
                                isSidebarOpen
                                    ? "px-4 py-2 w-full justify-start"
                                    : "w-12 justify-center",
                                "hover:bg-grey-300"
                            )}
                        >
                            <Link to={PATHS.ADMIN_DASHBOARD} className="flex items-center w-full"><span
                                className="material-symbols-outlined mr-2 text-[16px] leading-none">bar_chart_4_bars</span>
                                {isSidebarOpen && <span>Dashboard</span>}
                            </Link>
                        </Button>
                    </>
                )}


            </div>
            {isSidebarOpen && (
                <div className="flex-1 overflow-auto">
                    <React.Suspense
                        fallback={
                            <div className="flex flex-col flex-1 px-4 space-y-4 overflow-auto">
                                {Array.from({length: 10}).map((_, i) => (
                                    <div
                                        key={i}
                                        className="w-full h-6 rounded-md shrink-0 animate-pulse bg-zinc-200 dark:bg-zinc-800"
                                    />
                                ))}
                            </div>
                        }
                    >
                        <SidebarList/>
                    </React.Suspense>
                </div>
            )}
            <SidebarToggle/>
        </div>)
}
