//TODO: can be refactored because not super usefull


import {Source} from "../../models/messageModel.ts";

export function formatSimilaritySearch(fetchedResults: any[]) {
    return fetchedResults.map((result: any) => {
        return {
            id: result.source_id,
            source: result.source,
            distance: result?.distance,
            relatedQuestions: result.related_questions
        }
    })
}

export function extractIdsFromResults(results: any[]) {
    if (!results) {
        return []
    }
    return results.map((result) => result.id)
}

export function extractIdsFromSource(source: Source[]) {
    if (!source) {
        return []
    }
    return source.map((result) => result.knowledge_document.id)
}
