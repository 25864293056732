import {MemoizedReactMarkdown} from "./markdown.tsx";

const SimpleMarkdown: React.FC<{ content: string }> = ({content}) => {
    return (
        <MemoizedReactMarkdown
            className={`prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0`}
        >
            {content}
        </MemoizedReactMarkdown>)
}

export default SimpleMarkdown;
