import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllKnowledgeChange} from "../../service/admin/adminKnowledgeManagementService.ts";
import KnowledgeBaseChangeList from "../../components/admin/knowledge-change-list.tsx";
import {AGOKnowledgeDocument} from "../../models/knowledgeBaseModel.ts";

export default function AdminListKnowledgeChangePage() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [knowledgeBaseChanges, setKnowledgeBaseChanges] = useState<AGOKnowledgeDocument[]>([]);

    useEffect(() => {
        const fetchKnowledgeChanges = async () => {
            try {
                const chatThreads = await getAllKnowledgeChange(navigate);
                setKnowledgeBaseChanges(chatThreads);
            } catch (err) {
                setError("Failed to fetch the message. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeChanges();
    }, [navigate]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-screen">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <KnowledgeBaseChangeList knowledge_changes={knowledgeBaseChanges}/>
        </div>
    );
}
