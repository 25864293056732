import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";

export async function postSimilaritySearch(query: string, navigate: NavigateFunction) {

    try {
        const response = await fetchWithAuth(`/api/admin/debug-similarity-search`, {
            method: 'POST',
            body: JSON.stringify({"query": query}),
        })
        return await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}

export async function postSimilaritySearchMessage(messageId: string, navigate: NavigateFunction) {

    try {
        const response = await fetchWithAuth(`/api/admin/similarity-search-message`, {
            method: 'POST',
            body: JSON.stringify({"id": messageId}),
        })
        return await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}
