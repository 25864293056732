import React, {createContext, ReactNode, useContext, useState} from 'react';


interface AdminContextType {
    currentChatListIds: string[];
    setCurrentChatListIds: (ids: string[]) => void;
}
const AdminContext = createContext<AdminContextType | undefined>(undefined);

interface AdminProviderProps {
    children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({children}) => {
    const [currentChatListIds, setCurrentChatListIds] = useState([] as string[]);
    return (
        <AdminContext.Provider value={{ currentChatListIds, setCurrentChatListIds }}>
            {children}
        </AdminContext.Provider>
    );
}

export function useAdminContext() {
    return useContext(AdminContext);
}
export const useAdmin = (): AdminContextType => {
    const context = useContext(AdminContext);
    if (!context) {
        throw new Error('useAdmin must be used within a AdminProvider');
    }
    return context;
};
