import {useMessages} from "../../context/messagesContext.tsx";
import {useNavigate, useParams} from "react-router-dom";
import {getChatMessagesFromThread} from "../../service/ChatService.ts";
import {useEffect, useCallback} from 'react';
import {Chat} from "../../components/chat.tsx";
import {useAdmin} from "../../context/adminContext.tsx";
import {Button} from "../../components/ui/button.tsx";

export default function AdminChatThreadPage() {
    const {thread_id} = useParams();
    const {setMessages, setThread} = useMessages();
    const navigation = useNavigate();
    const {currentChatListIds} = useAdmin();

    useEffect(() => {
        const fetchMessages = async () => {
            const response = await getChatMessagesFromThread(thread_id, navigation);
            setMessages(response.messages || []);
            setThread(response);
        };

        fetchMessages().then();
    }, [thread_id]);

    // Helper function to navigate to next or previous chat thread
    const navigateToThread = useCallback((offset: number) => {
        if (!thread_id || !currentChatListIds || currentChatListIds.length === 0) return;

        const currentIndex = currentChatListIds.indexOf(thread_id);
        if (currentIndex !== -1) {
            const newIndex = currentIndex + offset;
            if (newIndex >= 0 && newIndex < currentChatListIds.length) {
                const newThreadId = currentChatListIds[newIndex];
                navigation(`/admin/chat/${newThreadId}`);
            }
        }
    }, [thread_id, currentChatListIds, navigation]);

    return (
        <>
            <div className="flex justify-between">
                <Button
                    variant="outline"
                    size="icon"
                    onClick={() => navigation(`/admin/conversations`)}
                    className="absolute top-[5%] -translate-y-1/2 left-5 z-10"
                >
                    <span className="material-symbols-outlined">close</span>
                    <span className="sr-only">Back to conversations</span>
                </Button>
                <Button
                    variant="outline"
                    size="icon"
                    disabled={!thread_id || currentChatListIds.indexOf(thread_id) === 0}
                    onClick={() => navigateToThread(-1)}
                    className="absolute top-[40%] -translate-y-1/2 left-5 z-10"
                >
                    <span className="material-symbols-outlined">navigate_before</span>
                    <span className="sr-only">Previous</span>
                </Button>
                <Button
                    variant="outline"
                    size="icon"
                    disabled={!thread_id || currentChatListIds.indexOf(thread_id) === currentChatListIds.length - 1}
                    onClick={() => navigateToThread(1)}
                    className="absolute top-[40%] -translate-y-1/2 right-5 z-10"
                >
                    <span className="material-symbols-outlined">navigate_next</span>
                    <span className="sr-only">Next</span>
                </Button>
            </div>
            <Chat isStaff={true} />
        </>
    );
}
