import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";

export async function getKnowledgeBaseTree(navigate: NavigateFunction, id: string) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/get-tree', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: id})
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}

export async function getKnowledgeBaseTreeFromPath(navigate: NavigateFunction, source_name?: string, page_id?: string) {
    try {
        const response = await fetchWithAuth('/api/knowledge_management/sync/get-tree-from-path', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({source_name: source_name, page_id: page_id})
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}