import {useSidebar} from '../lib/hooks/use-sidebar'
import {Button} from './ui/button'
import {cn} from "../lib/utils.ts";


export function SidebarToggle() {
    const {toggleSidebar} = useSidebar();

    return (
        <Button
            className={cn(
                "flex items-center h-12 w-12",
                "bg-white text-black rounded-lg",
                "justify-center",
                "hover:bg-grey-300"
            )}
            onClick={() => toggleSidebar()}
        >
            <img src="/menu-burger.svg" className="text-[16px] leading-none text-black"
                 alt="Menu"/>
            <span className="sr-only">Toggle Sidebar</span> {/* Screen reader text */}
        </Button>
    );
}
