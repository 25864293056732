import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {ConfigFromPermission, HomePageConfig} from "../models/configType.ts";
import {getConfigFromPermission} from "../service/ConfigService.ts";
import {useNavigate} from "react-router-dom";

interface ConfigContextType {
    configsFromPermission: ConfigFromPermission[];
    getHomePageConfig: () => null | HomePageConfig;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

export const useConfig = (): ConfigContextType => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
};

interface ConfigProviderProps {
    children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({children}) => {
    const [configsFromPermission, setConfigsFromPermission] = useState<ConfigFromPermission[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchConfigs = async () => {
            const configs = await getConfigFromPermission(navigate);
            setConfigsFromPermission(configs);
        };
        if (!getHomePageConfig()) {
            fetchConfigs();
        }
    }, [navigate]);

    const getHomePageConfig = (): null | HomePageConfig => {
        if (!configsFromPermission || configsFromPermission.length === 0 || !configsFromPermission[0]?.homePage) {
            return null;
        }

        return configsFromPermission[0].homePage;
    }

    return (
        <ConfigContext.Provider value={{configsFromPermission, getHomePageConfig}}>
            {children}
        </ConfigContext.Provider>
    );
}
