import {useNavigate} from "react-router-dom";
import {lazy, useEffect, useState} from "react";
import {getDashboardData} from "../../service/admin/adminDashboardService.ts";

const Pie = lazy(() => import("react-chartjs-2").then((module) => ({default: module.Pie})));

const loadChartJS = async () => {
    const {ArcElement, Chart: ChartJS, Legend, Tooltip} = await import("chart.js");
    ChartJS.register(ArcElement, Tooltip, Legend);
    return {ArcElement, ChartJS, Legend, Tooltip};
};

interface EvaluationCounts {
    OK: number;
    KO: number;
    NEUTRAL: number;
    NOT_EVALUATED: number;
}

interface EvaluationData {
    total: EvaluationCounts;
    staff: EvaluationCounts;
    non_staff: EvaluationCounts;
}

interface PerformanceData {
    number_of_threads: number;
    number_of_users: number;
    returning_users: number;
    average_messages_per_thread: number;
    escalation_rate: number;
    first_contact_resolution_rate: number;
}

function cleanEvaluationData(evaluationData: Partial<EvaluationData>): EvaluationData {
    const cleanCounts = (counts: Partial<EvaluationCounts>): EvaluationCounts => ({
        OK: counts.OK ?? 0,
        KO: counts.KO ?? 0,
        NEUTRAL: counts.NEUTRAL ?? 0,
        NOT_EVALUATED: counts.NOT_EVALUATED ?? 0,
    });

    return {
        total: cleanCounts(evaluationData.total ?? {}),
        staff: cleanCounts(evaluationData.staff ?? {}),
        non_staff: cleanCounts(evaluationData.non_staff ?? {}),
    };
}

export default function AdminDashboardPage() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [evaluationData, setEvaluationData] = useState<EvaluationData | null>(null);
    const [performanceData, setPerformanceData] = useState<PerformanceData | null>(null);
    const [chartLoaded, setChartLoaded] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Load ChartJS dynamically on mount
        loadChartJS().then(() => setChartLoaded(true));
    }, []);

    const fetchEvaluationData = async () => {
        setLoading(true);
        try {
            const params: any = {};
            if (startDate) params.start_date = startDate;
            if (endDate) params.end_date = endDate;
            const dashboardData = await getDashboardData(navigate, params);
            setEvaluationData(cleanEvaluationData(dashboardData.evaluation));
            setPerformanceData(dashboardData.performance);
        } catch (err) {
            setError("Failed to fetch the data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvaluationData();
    }, [navigate]);

    if (loading) {
        return <div
            className="flex justify-center items-center h-screen text-gray-700 text-lg font-semibold">Loading...</div>;
    }

    if (error) {
        return <div
            className="flex justify-center items-center h-screen text-red-500 text-lg font-semibold">{error}</div>;
    }

    // Create pie chart data
    const generatePieChartData = (data: EvaluationCounts) => ({
        labels: ["OK", "KO", "NEUTRAL"],
        datasets: [
            {
                label: "Evaluation Count",
                data: [data.OK, data.KO, data.NEUTRAL],
                backgroundColor: ["#34D399", "#EF4444", "#FBBF24", "#9CA3AF"],
                borderColor: "#ffffff",
                borderWidth: 2,
                hoverOffset: 10,
                hoverBackgroundColor: ["#10B981", "#DC2626", "#F59E0B", "#6B7280"],
            },
        ],
    });

    const calculateTotal = (data: EvaluationCounts) => {
        return (data?.OK ?? 0) + (data?.KO ?? 0) + (data?.NEUTRAL ?? 0) + (data?.NOT_EVALUATED ?? 0);
    };

    const calculateEvaluatedTotal = (data: EvaluationCounts) => {
        return (data?.OK ?? 0) + (data?.KO ?? 0) + (data?.NEUTRAL ?? 0);
    };

    const renderSummary = (title: string, data: EvaluationCounts) => {
        const total = calculateTotal(data);
        const evalutatedtotal = calculateEvaluatedTotal(data);

        return (
            <div className="bg-gray-50 p-4 rounded-md shadow">
                <h2 className="text-xl font-semibold mb-4 text-gray-700">{title}: {total}</h2>
                <p className="text-gray-600 font-semibold">Not Evaluated
                    Threads: {data.NOT_EVALUATED} ({(total > 0 ? (((data?.NOT_EVALUATED ?? 0) / total) * 100) : 0).toFixed(1)}%)</p>
                <ul className="list-disc pl-5 text-gray-600">
                    <li>OK: {data.OK} ({(evalutatedtotal > 0 ? (((data?.OK ?? 0) / evalutatedtotal) * 100) : 0).toFixed(1)}%)</li>
                    <li>KO: {data.KO} ({(evalutatedtotal > 0 ? (((data?.KO ?? 0) / evalutatedtotal) * 100) : 0).toFixed(1)}%)</li>
                    <li>NEUTRAL: {data.NEUTRAL} ({(evalutatedtotal > 0 ? (((data?.NEUTRAL ?? 0) / evalutatedtotal) * 100) : 0).toFixed(1)}%)</li>
                </ul>

                <div className="mt-4">
                    <Pie
                        data={generatePieChartData(data)}
                        options={{
                            plugins: {
                                legend: {position: 'bottom'},
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            const value = context.raw as number;
                                            const percentage = evalutatedtotal > 0 ? ((value / evalutatedtotal) * 100).toFixed(2) : 0;
                                            return ` ${value} (${percentage}%)`;
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderPerformanceCard = (title: string, value: string) => (
        <div className="rounded-lg shadow-lg bg-white p-6 border border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
                    <p className="mt-1 text-3xl font-bold text-gray-900">{value}</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6 text-gray-800">Thread Performance Overview</h1>
            <div className="flex space-x-6 mb-6">
                <div className="flex flex-col w-1/4">
                    <label className="block mb-2 font-medium text-gray-700">Quick Select</label>
                    <select
                        className="border rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                        onChange={(e) => {
                            const value = e.target.value;
                            const today = new Date();
                            if (value === "last_day") {
                                const lastday = new Date();
                                lastday.setDate(today.getDate() - 1);
                                setStartDate(lastday.toISOString().split("T")[0]);
                                setEndDate(today.toISOString().split("T")[0]);
                            } else if (value === "last_week") {
                                const lastWeek = new Date();
                                lastWeek.setDate(today.getDate() - 7);
                                setStartDate(lastWeek.toISOString().split("T")[0]);
                                setEndDate(today.toISOString().split("T")[0]);
                            } else if (value === "last_month") {
                                const lastMonth = new Date();
                                lastMonth.setMonth(today.getMonth() - 1);
                                setStartDate(lastMonth.toISOString().split("T")[0]);
                                setEndDate(today.toISOString().split("T")[0]);
                            }
                        }}
                    >
                        <option value="">Select...</option>
                        <option value="last_day">Last Day</option>
                        <option value="last_week">Last Week</option>
                        <option value="last_month">Last Month</option>
                    </select>
                </div>
                <div className="flex flex-col w-1/4">
                    <label className="block mb-2 font-medium text-gray-700">Start Date</label>
                    <input
                        type="date"
                        className="border rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                        value={startDate || ""}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="flex flex-col w-1/4">
                    <label className="block mb-2 font-medium text-gray-700">End Date</label>
                    <input
                        type="date"
                        className="border rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                        value={endDate || ""}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <button
                    onClick={fetchEvaluationData}
                    className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition"
                    disabled={!startDate || !endDate || loading}
                >
                    {loading ? "Loading..." : "Fetch Data"}
                </button>
            </div>

            {loading && <p className="text-blue-500 text-lg font-semibold">Loading data...</p>}
            {error && <p className="text-red-500 text-lg font-semibold">{error}</p>}

            {performanceData && (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-10">
                    {renderPerformanceCard("Number of Threads", performanceData.number_of_threads.toString())}
                    {renderPerformanceCard("Number of Users", performanceData.number_of_users.toString())}
                    {renderPerformanceCard("Returning Users", performanceData.returning_users.toString())}
                    {renderPerformanceCard("Average Messages per Thread", performanceData.average_messages_per_thread.toFixed(2).toString())}
                    {renderPerformanceCard("Escalation Rate", `${(performanceData.escalation_rate * 100).toFixed(0)}%`)}
                    {renderPerformanceCard("First Contact Resolution Rate", `${(performanceData.first_contact_resolution_rate * 100).toFixed(0)}%`)}
                </div>
            )}

            {evaluationData && chartLoaded && (
                <div className="grid grid-cols-3 gap-4 mt-8">
                    {renderSummary("Total Threads", evaluationData.total)}
                    {renderSummary("Customer Only", evaluationData.non_staff)}
                    {renderSummary("Staff Only", evaluationData.staff)}
                </div>
            )}
        </div>
    );
}
