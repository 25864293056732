import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {postSimilaritySearchMessage} from "../../service/admin/adminDebugSimilaritySearchService.ts";
import {
    extractIdsFromSource,
    formatSimilaritySearch
} from "../../service/similarity-search/FormatSimilaritySearchService.ts";
import {Source} from "../../models/messageModel.ts";
import {JsonSource} from "../../models/knowledgeBaseModel.ts";


interface ResultItem {
    id: string;
    source: JsonSource;
    distance?: number;
    relatedQuestions: boolean;
}

interface ResultsTableProps {
    query: string;
    results: ResultItem[];
    idsAlreadySelected?: string[];
}

interface DisplaySourceProps {
    sources: Source[];
}

const DisplaySources: React.FC<DisplaySourceProps> = ({sources}) => (
    <div className="col-span-1 bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-2">Results for previous message sources</h3>
        <table className="min-w-full">
            <thead>
            <tr>
                <th className="py-2 px-4 border-b">#</th>
                <th className="py-2 px-4 border-b">Title</th>
                <th className="py-2 px-4 border-b">Source name</th>
                <th className="py-2 px-4 border-b">Link Doc</th>
            </tr>
            </thead>
            <tbody>
            {sources && sources.map((source, index) => (
                <tr
                    key={index} className="bg-blue-200"
                >
                    <td className="py-2 px-4">{index + 1}</td>
                    <td className="py-2 px-4">{source.knowledge_document.title}</td>
                    <td className="py-2 px-4">
                        {source.knowledge_document.source?.name}
                    </td>
                    <td className="py-2 px-4">
                        <Link
                            to={'/knowledge-base/?source_id=' + source.knowledge_document.id}>{source.knowledge_document.id}</Link>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
);


const ResultsTable: React.FC<ResultsTableProps> = ({query, results, idsAlreadySelected}) => (
    <div className="col-span-1 bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-2">Results for Query: {query}</h3>
        <table className="min-w-full">
            <thead>
            <tr>
                <th className="py-2 px-4 border-b">#</th>
                <th className="py-2 px-4 border-b">Title</th>
                <th className="py-2 px-4 border-b">Distance</th>
                <th className="py-2 px-4 border-b">Origin</th>
                <th className="py-2 px-4 border-b">ID</th>
            </tr>
            </thead>
            <tbody>
            {results && results.map((result, index) => (
                <tr
                    key={index}
                    className={`border-b ${
                        idsAlreadySelected && idsAlreadySelected.includes(result.id) ? "bg-blue-200" : ""
                    }`}
                >
                    <td className="py-2 px-4">{index + 1}</td>
                    <td className="py-2 px-4">{result?.source?.title}</td>
                    <td className="py-2 px-4">{result.distance}</td>
                    <td className="py-2 px-4">
                        {result.source.name}
                        {result.relatedQuestions && (
                            <span
                                className="ml-2 bg-blue-100 text-blue-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">
                                    Related Questions
                                </span>
                        )}
                    </td>
                    <td className="py-2 px-4">
                        <Link to={'/knowledge-base/?source_id=' + result.id}>{result.id}</Link>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
);


export default function AdminDebugSimilaritySearchMessagePage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<string | null>(null);
    const [previousQuestions, setPreviousQuestions] = useState<string | null>(null);
    const [resultPreviousQuestions, setResultPreviousQuestions] = useState<
        {
            id: string;
            source: JsonSource;
            distance: number;
            relatedQuestions: boolean;
        }[]>([]);
    const [results, setResults] = useState<{
        id: string,
        source: JsonSource;
        distance: number,
        relatedQuestions: boolean
    }[]>([]);

    const [sources, setSources] = useState<Source[]>([]);


    const navigate = useNavigate();
    const {messageId} = useParams();

    const fetchResults = async () => {
        if (!messageId) {
            return;
        }
        setLoading(true);
        setError(null);
        setResults([]);
        try {
            const data = await postSimilaritySearchMessage(messageId, navigate);
            setResults(formatSimilaritySearch(data.results_current_question));
            setResultPreviousQuestions(formatSimilaritySearch(data.result_previous_questions));
            setSources(data.previous_sources);
            setCurrentQuestion(data.current_question);
            setPreviousQuestions(data.previous_questions);

        } catch (err) {
            setError("Failed to fetch the messages. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResults();
    }, [messageId]);


    if (!messageId) {
        return (
            <div className="min-h-screen bg-gray-100 p-4">
                <div className="flex justify-center items-center text-red-500">No message ID provided.</div>
            </div>
        );
    }
    return (
        <div className="min-h-screen bg-gray-100 p-4">
            {loading && <div className="flex justify-center items-center">Loading...</div>}

            {error && <div className="flex justify-center items-center text-red-500">{error}</div>}

            {!loading && !error && (
                <div className="mt-4 grid grid-cols-3 gap-4">
                    <DisplaySources sources={sources}/>
                    <ResultsTable key="1" query={previousQuestions ?? ''} results={resultPreviousQuestions}
                                  idsAlreadySelected={extractIdsFromSource(sources)}/>
                    <ResultsTable key="2" query={currentQuestion ?? ''} results={results}/>
                </div>
            )}
        </div>
    );
}
