import {getAgoConfig} from "../service/ConfigService.ts";
import {VideoVimeoPlayer} from "./VideoVimeoPlayer.tsx";
import {useConfig} from "../context/configContext.tsx";


export function EmptyScreen() {
    const config = getAgoConfig();

    return (
        <div className="lg:mt-[200px] mx-auto max-w-3xl px-4 flex flex-col items-center text-center">
            <img
                src="/sparkle.svg"
                alt="Bot"
                className="material-symbols-outlined rounded-full border-2 border-[#6683cf] bg-blue-500"
                style={{width: '32px', height: '32px', padding: '20px', boxSizing: 'content-box'}}
            />
            <div className="text-gray-600 font-medium text-xl mt-4">
                <div className="title">
                    {config.title.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            </div>
            <div className="text-xl text-gray-500 mt-4">{config.subtitle}</div>
            <ConfiguralHomePage/>
        </div>
    );
}

function ConfiguralHomePage() {
    const {getHomePageConfig} = useConfig();
    const homePageConfig = getHomePageConfig();

    if (!homePageConfig) {
        return <></>;
    }

    if (homePageConfig.type === 'vimeo') {
        return <VideoVimeoPlayer vimeoId={Number(homePageConfig.id)}/>;
    }

    return <></>;
}