import React from 'react'
import {cn} from "../lib/utils.ts";

export function FooterText({className, ...props}: React.ComponentProps<'p'>) {
    return (
        <p
            className={cn(
                'px-2 text-center text-sm leading-normal text-muted-foreground',
                className
            )}
            {...props}
        >
        </p>
    )
}
