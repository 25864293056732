import {nanoid} from "../lib/utils.ts";
import {Chat} from "../components/chat.tsx";
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {getUser} from '../service/UserService';
import {loginFlow} from "../service/AuthService.ts";

export default function HomePage() {
    const id = nanoid()
    const navigate = useNavigate();

    useEffect(() => {
        const user = getUser();

        if (user && user.permissions) {
            return;
        } else {
            loginFlow(navigate);
        }
    }, [navigate]);

    return (
        <Chat id={id}/>
    )
}
