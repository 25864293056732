import {KnowledgeNode} from "../../models/knowledgeBaseModel.ts";

// Helper function to mimic path.dirname
const getDirname = (filePath: string): string => {
    if (!filePath) return '';

    const segments = filePath.split('/');
    segments.pop(); // Remove the last part (file or directory)
    return segments.join('/');
};

// Helper function to resolve relative paths and handle absolute paths
const resolvePath = (from: string, to: string): string => {
    // Handle relative paths
    if (!from || !to) {
        return to;
    }

    // If the path is already absolute (starts with http:// or https://), return it as-is
    if (to.startsWith('http://') || to.startsWith('https://')) {
        return to;
    }

    const stack = from.split('/');
    const parts = to.split('/');

    // Remove the last part if it's a file (i.e., resolve from directory level)
    if (stack[stack.length - 1].includes('.')) stack.pop();

    for (const part of parts) {
        if (part === '..') {
            stack.pop(); // Go up one level
        } else if (part !== '.' && part !== '') {
            stack.push(part);
        }
    }

    return stack.join('/');
};

// Helper function to extract the path portion from an absolute URL
const getPathFromURL = (url: string): string => {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname; // Returns the path part of the URL (e.g. /Connect/api-doc/connect/connect.html)
    } catch (error) {
        return url; // If not a valid URL, return the input as is
    }
};

export const findNodeBySourcePath = (
    nodes: KnowledgeNode[],
    href: string,
    currentNodeSourcePath: string
): KnowledgeNode | undefined => {
    let resolvedPath: string;

    // Check if the href is an absolute URL
    if (href.startsWith('http://') || href.startsWith('https://')) {
        resolvedPath = getPathFromURL(href); // Extract only the path from the absolute URL
    } else {
        // Resolve the relative href against the current node's directory
        const currentNodeDir = getDirname(currentNodeSourcePath);
        resolvedPath = resolvePath(currentNodeDir, href); // Resolve the path relative to the current node's source path
    }

    // Iterate through the nodes to find a match
    for (const node of nodes) {
        // Check if the resolved path matches the node's source_path
        if (resolvePath('/', node.source_path) === resolvePath('/', resolvedPath)) {
            return node;
        }

        // Recursively search in children if present
        if (node.children) {
            const found = findNodeBySourcePath(node.children, href, currentNodeSourcePath);
            if (found) return found;
        }
    }

    return undefined;
};

export function transformMarkdownLinkURL(
    markdown: string,
    currentNodeSourcePath: string,
    knowledgeTree: KnowledgeNode[]
): string {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g; // Regex to match markdown links [text](link)

    return markdown.replace(linkRegex, (match, text, href) => {
        //TODO: dirty fix to put in config or to remove when all Mirakl links are updated
        if (href.indexOf('/api-doc/')) {
            return match;
        }

        const foundNode = findNodeBySourcePath(knowledgeTree, href, currentNodeSourcePath);
        if (foundNode) {
            // Replace the href with the new ?source_id= format
            return `[${text}](/knowledge-base/${foundNode.source_name}/${foundNode.page_id})`;
        }
        // If not found, return the original match
        return match;
    });
}

export function replaceVimeoLinksWithIframe(markdown: string): string {
    // Regular expression to find [Video](https://vimeo.com/{VIDEO_ID}) with or without a trailing slash
    const vimeoUrlRegex = /\[Video\]\((https?:\/\/vimeo\.com\/(\d+)\/?\))/g;

    // Replace each Vimeo markdown link with an iframe
    return markdown.replace(vimeoUrlRegex, (_match, _p1, vimeoId) => {
        return `<iframe src="https://player.vimeo.com/video/${vimeoId}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
    });
}
