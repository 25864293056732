import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {FaLink, FaUnlink} from 'react-icons/fa';
import {getKnowledgeBaseTree, getKnowledgeBaseTreeFromPath} from "../service/knowledge-base/knowledgeBaseService.ts";
import {
    replaceVimeoLinksWithIframe,
    transformMarkdownLinkURL
} from "../service/markdown/transformMarkdownLinkURLService.ts";
import {useKnowledgeBase} from "../context/knowledgeBaseContext.tsx";
import {isStaff} from "../service/UserService.ts";
import {
    deleteOverrideKnowledgeContent,
    deleteRelatedQuestionsKnowledgeContent,
    overrideKnowledgeContent,
    updateExcludedFromSource,
    updateKnowledgeRelatedQuestions
} from "../service/admin/adminKnowledgeManagementService.ts";
import {transformMarkdownImageURLService} from "../service/markdown/transformMarkdownImageURLService.ts";

const ImprovedMarkdown = React.lazy(() => import('../components/ImprovedMarkdown.tsx'));
const RedocStandalone = React.lazy(() => import('redoc').then(module => ({default: module.RedocStandalone})));


export default function KnowledgeBasePage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {source_name} = useParams();
    const page_id = useParams()["*"];
    const {
        loading,
        setLoading,
        setKnowledgeBaseTree,
        selectedNode,
        setError,
        knowledgeBaseTree,
        error,
        setNodeSelectedFromParams,
        setCurrentDocument,
        currentDocument,
    } = useKnowledgeBase();

    const updatePageData = async (sourceId: string | null, page_id: string | undefined, source_name: string | undefined, navigate: any) => {
        const knowledgeTreeWithCurrentKd = sourceId ? await getKnowledgeBaseTree(navigate, sourceId) : await getKnowledgeBaseTreeFromPath(navigate, source_name, page_id);
        const knowledgeTree = knowledgeTreeWithCurrentKd.tree
        setKnowledgeBaseTree(knowledgeTree);
        setCurrentDocument(knowledgeTreeWithCurrentKd.current_node);
        setNodeSelectedFromParams(knowledgeTree, sourceId, source_name ?? "", page_id ?? "");
    }

    const [isEditing, setIsEditing] = useState(false);
    const [isEditingQuestion, setIsEditingQuestion] = useState(false);
    const [editedContent, setEditedContent] = useState<string>('');
    const [editedRelatedQuestions, setEditedRelatedQuestions] = useState<string>('');

    useEffect(() => {
        const fetchKnowledgeBaseTree = async () => {
            const sourceId = searchParams.get('source_id');
            if (sourceId || source_name) {
                try {
                    await updatePageData(sourceId, page_id, source_name, navigate);
                } catch (err) {
                    setError("Failed to fetch the tree. Please try again later.");
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchKnowledgeBaseTree().then();
    }, [navigate, source_name, page_id, searchParams]);

    useEffect(() => {
        if (!isEditing) {
            setIsEditingQuestion(false);
        }
    }, [isEditing]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-screen">{error}</div>;
    }

    if (!knowledgeBaseTree) {
        return <div>No knowledge base found</div>;
    }

    if (currentDocument && currentDocument.title) {
        document.title = currentDocument.title;
    }

    let pageContent = currentDocument?.content ?? '';
    const pageType = currentDocument?.type ?? 'markdown';
    const pageRelatedQuestions = currentDocument?.related_questions ?? '';

    const sourcePath = selectedNode ? selectedNode.source_path : '/';

    pageContent = replaceVimeoLinksWithIframe(pageContent);
    pageContent = transformMarkdownLinkURL(pageContent, sourcePath, [knowledgeBaseTree]);
    pageContent = transformMarkdownImageURLService(pageContent);

    const handleEditContentClick = () => {
        setIsEditing(true);
        setIsEditingQuestion(false);
        setEditedContent(pageContent);
    };

    const handleEditRelatedQuestionsClick = () => {
        setIsEditing(true);
        setIsEditingQuestion(true);
        setEditedRelatedQuestions(pageRelatedQuestions);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleSaveClick = async () => {
        const id = currentDocument?.id;
        if (!id) {
            return;
        }
        currentDocument.content = editedContent;
        setIsEditing(false);
        await overrideKnowledgeContent({'id': id, 'content': editedContent}, navigate);
    };

    const handleSaveRelatedQuestionsClick = async () => {
        const id = currentDocument?.id;
        if (!id) {
            return;
        }
        currentDocument.related_questions = editedRelatedQuestions;
        setIsEditing(false);
        await updateKnowledgeRelatedQuestions({'id': id, 'related_questions': editedRelatedQuestions}, navigate);
    };

    const handleDeleteOverrideClick = async () => {
        const id = currentDocument?.id;
        if (!id) {
            return;
        }

        try {
            setLoading(true);
            await deleteOverrideKnowledgeContent(id, navigate);
            await updatePageData(null, page_id, source_name, navigate);
        } catch (err) {
            setError("Failed to delete override content. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRelatedQuestionsClick = async () => {
        const id = selectedNode?.id;
        if (!id) {
            return;
        }

        try {
            setLoading(true);
            await deleteRelatedQuestionsKnowledgeContent(id, navigate);
            await updatePageData(null, page_id, source_name, navigate);
        } catch (err) {
            setError("Failed to delete related questions. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleToggleIncludeClick = async () => {
        const id = selectedNode?.id;
        if (!id) {
            return;
        }
        try {
            setLoading(true);
            await updateExcludedFromSource({
                'id': id,
                'excluded_from_source': !selectedNode.excluded_from_source
            }, navigate);
            selectedNode.excluded_from_source = !selectedNode?.excluded_from_source;
        } catch (err) {
            setError("Failed to delete related questions. Please try again later.");
        } finally {
            setLoading(false);
        }
    }

    const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedContent(event.target.value);
    };

    const handleRelatedQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedRelatedQuestions(event.target.value);
    };

    return (
        <div
            className="group w-full overflow-auto px-[50px] peer-[[data-state=open]]:lg:ml-[260px] peer-[[data-state=open]]:xl:ml-[300px]">
            {selectedNode && (
                isEditing ? (
                    <div>
                        <textarea
                            className="w-full h-[calc(100vh-10rem)] p-2 border border-gray-300 rounded"
                            value={!isEditingQuestion ? editedContent : editedRelatedQuestions}
                            onChange={!isEditingQuestion ? handleContentChange : handleRelatedQuestionChange}
                        />
                        <div className="mt-2 flex gap-2">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={!isEditingQuestion ? handleSaveClick : handleSaveRelatedQuestionsClick}
                            >
                                Save
                            </button>
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ) : (
                    (pageType === 'markdown') ?
                        <ImprovedMarkdown content={pageContent} raw={true}/> :
                        <RedocStandalone spec={JSON.parse(pageContent)}
                                         options={{
                                             hideDownloadButton: true,
                                             hideHostname: true,
                                             disableSearch: true,
                                             theme: {
                                                 breakpoints: { // HACK to force the Redoc in Responsive mode
                                                     small: '10000rem',
                                                     medium: '10001rem',
                                                     large: '10002rem',
                                                 },
                                             }
                                         }}
                        />
                )
            )}
            {isStaff() && !isEditing && (
                <div>
                    <div className="mt-4 flex gap-4 justify-center">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={handleEditContentClick}
                        >
                            Edit Content
                        </button>
                        <button
                            className="bg-purple-500 text-white px-4 py-2 rounded"
                            onClick={handleDeleteOverrideClick}
                        >
                            Restore default content
                        </button>
                    </div>
                    <div className="mt-4 flex gap-4 justify-center">
                        <span
                            className="inline-flex items-center rounded-full bg-blue-100 px-3 py-1 font-medium text-blue-800">
                            Source: {selectedNode?.doc_type ?? 'Unknown'}
                        </span>
                        <span
                            className={`inline-flex items-center rounded-full px-3 py-1 font-medium ${selectedNode?.excluded_from_source ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                            {selectedNode?.excluded_from_source ? 'Excluded' : 'Included'}
                        </span>
                        <button
                            className="bg-gray-500 text-white px-4 py-2 rounded ml-2 flex items-center"
                            onClick={handleToggleIncludeClick}
                        >
                            {selectedNode?.excluded_from_source ? <FaLink className="mr-2"/> :
                                <FaUnlink className="mr-2"/>}
                            {selectedNode?.excluded_from_source ? 'Include in Source' : 'Exclude from Source'}
                        </button>
                    </div>
                    <div className="mt-4 flex gap-4 justify-center">
                        <h2 className="text-2xl font-bold m-2 text-gray-800">Related Questions</h2>
                    </div>

                    <div className="mt-4 flex gap-4 justify-center">
                        <p
                            className="w-[calc(100vh-10rem)] h-[calc(30vh-10rem)] p-2 border border-gray-300 rounded">
                            {pageRelatedQuestions}
                        </p>
                    </div>
                    <div className="mt-4 flex gap-4 justify-center">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={handleEditRelatedQuestionsClick}
                        >
                            Edit related questions
                        </button>
                        <button
                            className="bg-purple-500 text-white px-4 py-2 rounded"
                            onClick={handleDeleteRelatedQuestionsClick}
                        >
                            Delete related questions
                        </button>
                    </div>
                </div>

            )}
        </div>
    );
}
