import {useEffect} from 'react'
import {SidebarItems} from './sidebar-items'
import {useNavigate} from "react-router-dom";
import {loadChats} from "../service/ChatService.ts";
import {useMessages} from "../context/messagesContext.tsx";

export function SidebarList() {
    const navigate = useNavigate();
    const {chatThreads, setChatThreads} = useMessages();

    useEffect(() => {
        // Define a function to load chats
        const fetchChats = async () => {
            // Call loadChats and handle the promise
            const response = await loadChats(navigate)
            setChatThreads(response)
        }

        fetchChats()
    }, [])

    return (
        <div className="flex flex-1 flex-col overflow-hidden mt-5">
            <div className="flex-1 overflow-auto">
                {chatThreads?.length ? (
                    <div className="">
                        <SidebarItems chats={chatThreads}/>
                    </div>
                ) : (
                    <div className="p-8 text-center">
                        <p className="text-muted-foreground">No chat history</p>
                    </div>
                )}
            </div>
        </div>
    )
}
