import {exportThreads, ThreadsFilters} from "../service/admin/adminChatThreadService.ts";
import {useState} from "react";
import {MessageThreadEvaluation} from "../models/messageModel.ts";
import {cn} from "../lib/utils.ts";
import {useNavigate} from "react-router-dom";

export function ChatFilters({currentThreadsFilter, onApplyFilters}: {
    currentThreadsFilter?: ThreadsFilters,
    onApplyFilters: (filterParams: ThreadsFilters) => void
}) {
    const navigate = useNavigate();
    const [evaluation, setEvaluation] = useState<MessageThreadEvaluation | undefined>(currentThreadsFilter?.evaluation);
    const [startDate, setStartDate] = useState<string | undefined>(currentThreadsFilter?.startDate);
    const [endDate, setEndDate] = useState<string | undefined>(currentThreadsFilter?.endDate);
    const [emailFilter, setEmailFilter] = useState<string | undefined>(currentThreadsFilter?.email);
    const [supportAsked, setSupportAsked] = useState<boolean>(currentThreadsFilter?.supportAsked ?? false);
    const [collectionName, setCollectionName] = useState<string | undefined>(currentThreadsFilter?.collectionName);
    const [threadsFilter] = useState<ThreadsFilters>(() => {
        const savedFilter = localStorage.getItem('threadsFilter');
        return savedFilter ? JSON.parse(savedFilter) : {currentPage: 1};
    });

    const handleRefresh = () => {
        onApplyFilters({
            evaluation,
            startDate,
            endDate,
            email: emailFilter,
            supportAsked,
            collectionName,
            currentPage: 1,
        });
    };

    const handleClear = () => {
        setEvaluation(undefined);
        setStartDate(undefined);
        setEndDate(undefined);
        setEmailFilter(undefined);
        setSupportAsked(false);
        setCollectionName(undefined);
        onApplyFilters({
            evaluation: undefined,
            startDate: undefined,
            endDate: undefined,
            email: undefined,
            supportAsked: false,
            collectionName: undefined,
            currentPage: 1,
        });
    };

    return (
        <section className="bg-white p-4 rounded-lg shadow-md mb-4">
            <div className="relative">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 mb-2">
                    <div className="flex flex-col">
                        <label htmlFor="evaluationFilter" className="font-medium mb-1">
                            Evaluation Status:
                        </label>
                        <select
                            id="evaluationFilter"
                            value={evaluation ?? ''}
                            onChange={(e) => setEvaluation(e.target.value as MessageThreadEvaluation)}
                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">All</option>
                            <option value="OK">OK</option>
                            <option value="KO">KO</option>
                            <option value="NEUTRAL">Neutral</option>
                            <option value="NOT_EVALUATED">Not Evaluated</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="collectionName" className="font-medium mb-1">
                            Collection Name:
                        </label>
                        <select
                            id="collectionName"
                            value={collectionName ?? ''}
                            onChange={(e) => setCollectionName(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">All</option>
                            <option value="seller">Seller</option>
                            <option value="operator">Operator</option>
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="startDate" className=" font-medium mb-1">
                            Start Date:
                        </label>
                        <input
                            type="datetime-local"
                            id="startDate"
                            value={startDate ?? ''}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="endDate" className=" font-medium mb-1">
                            End Date:
                        </label>
                        <input
                            type="datetime-local"
                            id="endDate"
                            value={endDate ?? ''}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="emailFilter" className=" font-medium mb-1">
                            Email Address:
                        </label>
                        <input
                            type="email"
                            id="emailFilter"
                            value={emailFilter ?? ''}
                            onChange={(e) => setEmailFilter(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="supportAsked"
                                checked={supportAsked}
                                onChange={(e) => setSupportAsked(e.target.checked)}
                                className="mr-2"
                            />
                            <label htmlFor="supportAsked" className=" font-medium">
                                Support Asked
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={handleRefresh}
                        className="p-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Apply
                    </button>
                    <button
                        onClick={handleClear}
                        className="p-2 bg-gray-500 text-white rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Reset
                    </button>
                    <button
                        className={cn(
                            "p-2 bg-purple-500 text-white rounded-md shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500",
                        )}
                        onClick={async () => {
                            await exportThreads(navigate, threadsFilter)
                        }}
                    >
                        <div className="flex items-center w-full">
                            <span className="material-symbols-outlined mr-2 text-[16px] leading-none">download</span>
                            <span>Export Conversations</span>
                        </div>
                    </button>
                </div>
            </div>
        </section>
    );
}