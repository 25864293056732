//TODO: make it less specific to the Mirakl

import {getApiBaseUrl} from "../fetchWithAuth.ts";

export function transformMarkdownImageURLService(markdown: string) {
    let apiImageBaseUrl = 'VITE_IMAGE_DOC_BASE_URL_PLACEHOLDER';

    const apiImageBaseUrlEnv = import.meta.env.VITE_IMAGE_DOC_BASE_URL ?? "http://localhost/images-from-doc/";

    if (apiImageBaseUrl.indexOf('PLACEHOLDER') !== -1) {
        apiImageBaseUrl = apiImageBaseUrlEnv;
    }
    const apiBaseUrl = getApiBaseUrl()

    return markdown.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, (_match, altText, relativeUrl) => {
        // Split the URL to separate the actual URL part and the optional title part.
        const urlParts = relativeUrl.split(/(?<=\))\s*"(.*)"/);
        const imageUrl = urlParts[0].trim();
        const title = urlParts.length > 1 ? `"${urlParts[1]}"` : '';

        if (imageUrl.includes('__API_BASE_URL__')) {
            const newUrl = imageUrl.replace('__API_BASE_URL__', apiBaseUrl);
            return `![${altText}](${newUrl})${title} `;
        } else {
            // Remove '../' from the start of the relative URL
            const cleanedUrl = imageUrl.replace(/^(\.\.\/)+/, '');
            const newUrl = `${apiImageBaseUrl}${cleanedUrl}`;
            return `![${altText}](${newUrl})${title} `;
        }
    });
}
