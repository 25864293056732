import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";

export async function getDashboardData(navigate: NavigateFunction, params: any) {
    try {
        const response = await fetchWithAuth('/api/business_messages/sync/dashboard/analytics', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })

        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        navigate('/')
    }
}
