import {Sheet, SheetContent, SheetTrigger} from './ui/sheet'
import {Sidebar} from "./sidebar.tsx";
import React from "react";
import {Button} from "./ui/button.tsx";

interface SidebarMobileProps {
    children: React.ReactNode
}

export function SidebarMobile({children}: SidebarMobileProps) {
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" className="-ml-2 flex size-9 p-0 lg:hidden">
                    <img src="/menu-burger.svg" className="mr-2 text-[16px] leading-none text-black"
                         alt="Menu"/>
                    <span className="sr-only">Toggle Sidebar</span>
                </Button>
            </SheetTrigger>
            <SheetContent
                side="left"
                className="inset-y-0 flex h-auto w-[300px] flex-col p-0"
            >
                <Sidebar className="flex">{children}</Sidebar>
            </SheetContent>
        </Sheet>
    )
}
