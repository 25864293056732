import * as React from 'react'

const LOCAL_STORAGE_KEY = 'sidebar'

interface SidebarContext {
    isSidebarOpen: boolean
    toggleSidebar: () => void
    closeSidebar: () => void
    isLoading: boolean
}

const SidebarContext = React.createContext<SidebarContext | undefined>(
    undefined
)

export function useSidebar() {
    const context = React.useContext(SidebarContext)
    if (!context) {
        throw new Error('useSidebarContext must be used within a SidebarProvider')
    }
    return context
}

interface SidebarProviderProps {
    children: React.ReactNode
}

export function SidebarProvider({children}: SidebarProviderProps) {
    const [isSidebarOpen, setSidebarOpen] = React.useState(true)
    const [isLoading, setLoading] = React.useState(true)

    React.useEffect(() => {
        const value = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (value) {
            setSidebarOpen(JSON.parse(value))
        }
        setLoading(false)
    }, [])

    const toggleSidebar = () => {
        setSidebarOpen(value => {
            const newState = !value
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState))
            return newState
        })
    }

    const closeSidebar = () => {
        setSidebarOpen(() => {
            const newState = false
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState))
            return newState
        })
    }

    if (isLoading) {
        return null
    }

    return (
        <SidebarContext.Provider
            value={{isSidebarOpen, toggleSidebar, isLoading, closeSidebar}}
        >
            {children}
        </SidebarContext.Provider>
    )
}
