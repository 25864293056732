import {Chat} from "../lib/types.ts";
import {Link, useLocation} from "react-router-dom";
import {cn} from "../lib/utils.ts";

interface SidebarItemsProps {
    chats?: Chat[];
}

export function SidebarItems({chats}: SidebarItemsProps) {
    const location = useLocation();
    const pathname = location.pathname;
    if (!chats?.length) return null;

    const now = new Date();

    let hasDisplayToday = false;
    let hasDisplayYesterday = false;
    let hasDisplay7days = false;
    let hasDisplay30days = false;
    let hasDisplay61days = false;

    return (
        <>
            {chats.map((chat) => {
                const chatDate = new Date(chat.last_message_date);
                const diffInDays = Math.floor((now.getTime() - chatDate.getTime()) / (1000 * 60 * 60 * 24));
                let separator = null;

                // Insert the separator for the first item or when crossing the threshold
                if (diffInDays == 0 && !hasDisplayToday) {
                    hasDisplayToday = true
                    separator = <div
                        className={cn("flex items-center rounded-lg h-12 px-4 py-2 w-full justify-start text-blue-600 font-medium")}>Today</div>;
                } else if (diffInDays == 1 && !hasDisplayYesterday) {
                    hasDisplayYesterday = true
                    separator = <div
                        className={cn("flex items-center rounded-lg h-12 mt-5 px-4 py-2 w-full justify-start text-blue-600 font-medium")}>Yesterday</div>;
                } else if (diffInDays > 1 && !hasDisplay7days) {
                    hasDisplay7days = true
                    separator = <div
                        className={cn("flex items-center rounded-lg h-12 mt-5 px-4 py-2 w-full justify-start text-blue-600 font-medium")}>Last
                        7 days</div>;
                } else if (diffInDays > 7 && !hasDisplay30days) {
                    hasDisplay30days = true
                    separator = <div
                        className={cn("flex items-center rounded-lg h-12 mt-5 px-4 py-2 w-full justify-start text-blue-600 font-medium")}>Last
                        30 days</div>;
                } else if (diffInDays > 30 && !hasDisplay61days) {
                    hasDisplay61days = true
                    separator = <div
                        className={cn("flex items-center rounded-lg h-12 mt-5 px-4 py-2 w-full justify-start text-blue-600 font-medium")}>Last
                        2 months</div>;
                }

                return (
                    <div key={chat.id}>
                        {separator}
                        <Link
                            to={'/chat/' + chat.id}
                            className={cn(
                                "flex items-center rounded-lg h-12",
                                "px-4 py-2 w-full justify-start",
                                "hover:bg-grey-300",
                                pathname.includes(chat.id) && 'bg-white'
                            )}
                        >
                            <div
                                className="mr-2 leading-none text-blue-900"
                                title={chat.title}
                            >
                                <span className="whitespace-nowrap">
                                    <span>{chat.title ?? 'No title'}</span>
                                </span>
                            </div>
                        </Link>
                    </div>
                );
            })}
        </>
    );
}
