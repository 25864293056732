import {SidebarDesktop} from '../components/sidebar-desktop';
import {Outlet} from "react-router-dom";

export default function ChatLayout() {
    return (
        <div className="relative flex h-[calc(100vh_-_theme(spacing.16))] overflow-hidden">
            <SidebarDesktop/>
            <Outlet/>
        </div>
    )
}

export function ChatLayoutWithoutSidebar() {
    return (
        <div className="relative flex h-[calc(100vh_-_theme(spacing.16))] overflow-hidden">
            <Outlet/>
        </div>
    )
}
