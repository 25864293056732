import fetchWithAuth, {getJsonAndHandleError} from "./fetchWithAuth.ts";
import {NavigateFunction} from "react-router-dom";

export async function ReactToMessagePositively(message_id: string | undefined, navigate: NavigateFunction): Promise<void> {
    if (!message_id) return

    try {
        const response = await fetchWithAuth(`/api/business_messages/sync/message-reaction/${message_id}/positive`, {
            method: 'POST',
        })
        await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}

export async function ReactToMessageNegatively(message_id: string | undefined, navigate: NavigateFunction): Promise<void> {
    if (!message_id) return

    try {
        const response = await fetchWithAuth(`/api/business_messages/sync/message-reaction/${message_id}/negative`, {
            method: 'POST',
        })
        await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}


