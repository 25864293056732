import {toast} from 'sonner'
import {IconSpinner} from './ui/icons'
import {z} from 'zod'
import {NavigateFunction, useNavigate} from 'react-router-dom'
import {ResultCode} from '../lib/utils'
import fetchWithAuth from "../service/fetchWithAuth.ts";
import {setToken, setUser} from "../service/UserService.ts";


export function authenticate(
    formData: FormData, navigate: (path: string) => void): void {

    const email = formData.get('email') as string
    const password = formData.get('password') as string

    const parsedCredentials = z
        .object({
            email: z.string().email(),
            password: z.string().min(5)
        })
        .safeParse({email, password})

    if (parsedCredentials.success) {
        fetchWithAuth('/api/token/pair', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username: email, password})
        })
            .then(response => {
                if (!response.ok) {
                    toast.error(ResultCode.InvalidCredentials)
                    return
                }
                return response.json()
            })
            .then(data => {
                if (!data) {
                    return
                }

                const accessToken = data.access
                setToken(accessToken)
                fetchWithAuth('/api/user/me', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                }).then(
                    response => {
                        if (!response.ok) {
                            toast.error(ResultCode.UnknownError)
                            return
                        }
                        return response.json()
                    }
                ).then(json => {
                    setUser(json)
                    toast.success(ResultCode.UserLoggedIn)
                    navigate('/')
                })
            })
            .catch(() => {
                toast.error(ResultCode.UnknownError)
            })
    } else {
        toast.error(ResultCode.UnknownError)
    }
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>, navigate: NavigateFunction) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    authenticate(formData, navigate);
};

export default function LoginForm() {
    const navigate = useNavigate();

    return (
        <form
            onSubmit={(event) => handleSubmit(event, navigate)}
            className="flex flex-col items-center gap-4 space-y-3"
        >
            <div
                className="w-full flex-1 rounded-lg border bg-white px-6 pb-4 pt-8 shadow-md  md:w-96 dark:bg-zinc-950">
                <h1 className="mb-3 text-2xl font-bold">Please log in to continue.</h1>
                <div className="w-full">
                    <div>
                        <label className="mb-3 mt-5 block text-sm font-medium text-zinc-400">
                            Email
                        </label>
                        <div className="relative">
                            <input
                                className="peer block w-full rounded-md border bg-zinc-50 px-2 py-[9px] outline-none placeholder:text-zinc-500 dark:border-zinc-800 dark:bg-zinc-950"
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                required
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <label
                            className="mb-3 mt-5 block text-sm font-medium text-zinc-400"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <div className="relative">
                            <input
                                className="peer block w-full rounded-md border bg-zinc-50 px-2 py-[9px] outline-none placeholder:text-zinc-500 dark:border-zinc-800 dark:bg-zinc-950"
                                id="password"
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                required
                                minLength={4}
                            />
                        </div>
                    </div>
                </div>
                <LoginButton/>
            </div>
        </form>
    )
}

function LoginButton() {
    // const { pending } = useFormStatus()
    const pending = false;

    return (
        <button
            className="my-4 flex h-10 w-full flex-row items-center justify-center rounded-md bg-zinc-900 p-2 font-semibold text-zinc-100 hover:bg-zinc-800 dark:bg-zinc-100 dark:text-zinc-900 dark:hover:bg-zinc-200"
            aria-disabled={pending}
        >
            {pending ? <IconSpinner/> : 'Log in'}
        </button>
    )
}

