import fetchWithAuth, {getJsonAndHandleError} from "./fetchWithAuth.ts";
import {NavigateFunction} from "react-router-dom";

export async function getChatMessagesFromThread(thread_id: string | undefined, navigate: NavigateFunction) {
    if (!thread_id) return

    try {
        const response = await fetchWithAuth('/api/business_messages/messages-from-thread/' + thread_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await getJsonAndHandleError(response, navigate);

    } catch (error) {
        navigate('/')
    }
}

export async function loadChats(navigate: NavigateFunction) {
    try {
        const response = await fetchWithAuth('/api/business_messages/threads-history', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return await getJsonAndHandleError(response, navigate)
    } catch (error) {
        console.error('Failed to load chats:', error)
        navigate('/login')
    }
}
