import "./instrument.js"
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Outlet, Route, Routes,} from "react-router-dom";
import {Toaster} from './components/ui/sonner.tsx';
import {TooltipProvider} from './components/ui/tooltip.tsx';
import {Header} from './components/header.tsx';
import {TailwindIndicator} from './components/tailwind-indicator.tsx';
import './globals.css'
import {SidebarProvider} from './lib/hooks/use-sidebar.tsx';
import {MessagesProvider} from "./context/messagesContext.tsx";
import AdminListAllChatThreadsPage from "./page/admin/AdminListAllChatThreadsPage.tsx";
import LoginPage from "./page/LoginPage.tsx";
import HomePage from "./page/homePage.tsx";
import ChatThreadPage from "./page/ChatThreadPage.tsx";
import ChatLayout, {ChatLayoutWithoutSidebar} from "./layout/ChatLayout.tsx";
import AdminChatThreadPage from "./page/admin/AdminChatThreadPage.tsx";
import AdminListKnowledgeChangePage from "./page/admin/AdminListKnowledgeChangePage.tsx";
import KnowledgeBasePage from "./page/KnowledgeBasePage.tsx";
import {getAgoConfig} from "./service/ConfigService.ts";
import React from "react";
import {KnowledgeBaseProvider} from "./context/knowledgeBaseContext.tsx";
import * as Sentry from "@sentry/react";
import AdminDashboardPage from "./page/admin/AdminDashboardPage.tsx";
import AdminDebugSimilaritySearchPage from "./page/admin/AdminDebugSimilaritySearchPage.tsx";
import AdminDebugSimilaritySearchMessagePage from "./page/admin/AdminSimilaritySearchMessagePage.tsx";
import {AdminProvider} from "./context/adminContext.tsx";
import {ConfigProvider} from "./context/configContext.tsx";

const rootElement = document.getElementById("root");
if (rootElement) {
    ReactDOM.createRoot(rootElement, {
        // Callback called when an error is thrown and not caught by an ErrorBoundary.
        // TODO: Update to React 19 when stable
        // onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
        //     console.warn('Uncaught error', error, errorInfo.componentStack);
        // }),
        // Callback called when React catches an error in an ErrorBoundary.
        // onCaughtError: Sentry.reactErrorHandler(),
        // Callback called when React automatically recovers from errors.
        onRecoverableError: Sentry.reactErrorHandler(),
    }).render(
        <AppRoot/>
    );
} else {
    console.error("Root element not found");
}

export const PATHS = {
    HOME: '/',
    KNOWLEDGE_BASE_PATH: '/knowledge-base/:source_name?/*',
    ADMIN_DASHBOARD: '/admin/dashboard',
}

let viteBasename = 'VITE_BASENAME_PLACEHOLDER';

let viteDefaultBasename = '';

try {
    // ⚠️ let this for the build process
    // @ts-ignore .env is not defined during the build process
    viteDefaultBasename = import.meta.env.VITE_AGO_BASENAME ?? '/';
} catch (e) {
    // We are in the build process
    viteDefaultBasename = 'build';
}

// If viteConfig contains the placeholder, replace it with the actual value from the environment
// Otherwise it means that we are running in production in a docker container
if (viteBasename.includes('PLACEHOLDER')) {
    viteBasename = viteDefaultBasename;
}

export default function AppRoot() {
    return (
        <BrowserRouter basename={viteBasename}>
            <Routes>
                <Route element={<Layout/>}>
                    <Route element={<ChatLayout/>}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/chat/:thread_id" element={<ChatThreadPage/>}/>
                        <Route path={PATHS.KNOWLEDGE_BASE_PATH} element={<KnowledgeBasePage/>}/>
                    </Route>
                    <Route element={<Admin/>}>
                        <Route element={<ChatLayoutWithoutSidebar/>}>
                            <Route path="/admin/chat/:thread_id" element={<AdminChatThreadPage/>}/>
                        </Route>
                        <Route path="/admin/conversations" element={<AdminListAllChatThreadsPage/>}/>
                        <Route path={PATHS.ADMIN_DASHBOARD} element={<AdminDashboardPage/>}/>
                        <Route path="/admin/knowledge-change-proposals" element={<AdminListKnowledgeChangePage/>}/>
                        <Route path="/admin/debug-similarity-search" element={<AdminDebugSimilaritySearchPage/>}/>
                        <Route path="/admin/dss" element={<AdminDebugSimilaritySearchPage/>}/>
                        <Route path="/admin/similarity-search-message/:messageId"
                               element={<AdminDebugSimilaritySearchMessagePage/>}/>
                    </Route>
                    <Route path="/login" element={<LoginPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}


function Layout() {
    const config = getAgoConfig();

    // Dynamically set title and favicon
    document.title = config.title || 'Ago';
    const faviconLink: HTMLLinkElement | null = document.querySelector("link[rel='icon']");
    if (faviconLink) {
        faviconLink.href = config.favicon || '/ago-favicon.png';
    }

    return (
        <div id="react-root" style={{"color": config.fontColor} as React.CSSProperties}>
            <ConfigProvider>
                <Toaster position="top-center"/>
                <MessagesProvider>
                    <KnowledgeBaseProvider>
                        <SidebarProvider>
                            <TooltipProvider>
                                <div className="flex flex-col min-h-screen">
                                    <Header/>
                                    <main className="flex flex-col flex-1">
                                        <Outlet/>
                                    </main>
                                </div>
                                <TailwindIndicator/>
                            </TooltipProvider>
                        </SidebarProvider>
                    </KnowledgeBaseProvider>
                </MessagesProvider>
            </ConfigProvider>
        </div>
    );
}


function Admin() {
    return (
        <AdminProvider>
            <Outlet/>
        </AdminProvider>
    );
}

