import {cn} from '../lib/utils'
import {ChatList} from './chat-list'
import {ChatPanel} from './chat-panel'
import {EmptyScreen} from './empty-screen'
import {Message, User} from '../lib/types'
import {useScrollAnchor} from '../lib/hooks/use-scroll-anchor'
import {useMessages} from "../context/messagesContext.tsx";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export interface ChatProps extends React.ComponentProps<'div'> {
    initialMessages?: Message[]
    id?: string
    user?: User
    isStaff?: boolean
}

export function Chat({id, className, user, isStaff}: ChatProps) {
    const {messages, thread} = useMessages();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        if (!path.includes('chat') && messages.length >= 2) {
            if (thread) {
                window.history.replaceState({}, '', `/chat/${thread.id}`)
            }
        }
    }, [id, path, messages])

    const {messagesRef, scrollRef, visibilityRef, isAtBottom, scrollToBottom} =
        useScrollAnchor()

    if (thread && thread.title) {
        document.title = thread.title;
    }

    return (
        <div
            className="group w-full overflow-auto pl-0 peer-[[data-state=open]]:lg:pl-[250px] peer-[[data-state=open]]:xl:pl-[265px]"
            ref={scrollRef}
        >
            <div
                className={cn('pb-[200px] pt-4 md:pt-10', className)}
                ref={messagesRef}
            >
                {messages?.length ? (
                    <ChatList messages={messages} isShared={false} user={user} isStaff={isStaff}
                              thread={thread ?? undefined}/>
                ) : (
                    <EmptyScreen/>
                )}
                <div className="w-full h-px" ref={visibilityRef}/>
            </div>
            <ChatPanel
                id={id}
                isAtBottom={isAtBottom}
                scrollToBottom={scrollToBottom}
                isStaff={isStaff}
            />
        </div>
    )
}
