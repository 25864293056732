import React, {useEffect, useState} from 'react';
import {ChevronDownIcon, ChevronRightIcon} from "@radix-ui/react-icons";
import {KnowledgeNode} from "../../models/knowledgeBaseModel.ts";
import {useKnowledgeBase} from "../../context/knowledgeBaseContext.tsx";

interface TreeNodeComponentProps {
    node: KnowledgeNode;
    onNodeSelect: (node: KnowledgeNode) => void;
    expandedIds: string[];
}

const KnowledgeTreeNode: React.FC<TreeNodeComponentProps> = ({node, onNodeSelect, expandedIds}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const hasChildren = Boolean(node.children?.length);
    const {selectedNode} = useKnowledgeBase();

    const toggleExpand = () => {
        setExpanded(prev => !prev);
    };

    const handleClick = () => {
        onNodeSelect(node);
        if (hasChildren) toggleExpand();
    };

    useEffect(() => {
        if (expandedIds.includes(node.id)) {
            setExpanded(true);
        }
    }, [node.id]);

    const isSelectedNode = selectedNode?.id === node.id;

    return (
        <div className="relative ml-1">
            <div
                onClick={handleClick}
                className={`cursor-pointer flex items-center justify-between py-0.5 px-3 
                    ${isSelectedNode ? 'text-blue-300' : 'text-grey-800'} 
                    hover:bg-blue-200 rounded-md transition-colors duration-200 font-medium`}
            >
                <span className="flex-grow pr-2 break-words">{node.title}</span>
                {hasChildren && (
                    expanded
                        ? <ChevronDownIcon className="w-5 h-5 text-gray-500 transition-transform duration-200"/>
                        : <ChevronRightIcon className="w-5 h-5 text-gray-500 transition-transform duration-200"/>
                )}
            </div>

            {(expanded || isSelectedNode) && (
                <div
                    className={`absolute top-0 left-0 w-[3px] h-full ${isSelectedNode ? 'bg-blue-300' : 'bg-grey-300'} rounded-full transition-all duration-300`}/>
            )}

            {expanded && hasChildren && (
                <div className="transition-all duration-300 ease-out pl-1">
                    {node.children && node.children.map(child => (
                        <KnowledgeTreeNode
                            key={child.id}
                            node={child}
                            onNodeSelect={onNodeSelect}
                            expandedIds={expandedIds}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default KnowledgeTreeNode;
