import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ChatThreadList from "../../components/chat-thread-list.tsx";
import {getAllThreads, ThreadsFilters} from "../../service/admin/adminChatThreadService.ts";
import {MessageThread} from "../../models/messageModel.ts";
import {ChatFilters} from "../../components/chat-filters.tsx";
import Pagination from "../../components/pagination.tsx";
import {useAdmin} from "../../context/adminContext.tsx";

export default function AdminListAllChatThreadsPage() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const {setCurrentChatListIds} = useAdmin();
    const navigate = useNavigate();
    const [currentsChatThreads, setCurrentsChatThreads] = useState<MessageThread[]>([]);
    const [threadsFilter, setThreadsFilter] = useState<ThreadsFilters>(() => {
        const savedFilter = localStorage.getItem('threadsFilter');
        return savedFilter ? JSON.parse(savedFilter) : {currentPage: 1};
    });

    const [totalResults, setTotalResults] = useState(1);

    const fetchMessage = async (filterParams = {}) => {
        try {
            setLoading(true);
            const chatThreads = await getAllThreads(navigate, filterParams);
            setCurrentsChatThreads(chatThreads.items);
            setCurrentChatListIds(chatThreads.items.map((thread: { id: string }) => thread.id));
            setTotalResults(chatThreads.count);
        } catch (err) {
            setError("Failed to fetch the message. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessage(threadsFilter);
    }, [navigate, threadsFilter]);

    useEffect(() => {
        if (threadsFilter) {
            localStorage.setItem('threadsFilter', JSON.stringify(threadsFilter));
        }
    }, [threadsFilter]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-screen">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <ChatFilters currentThreadsFilter={threadsFilter}
                         onApplyFilters={(filterParams) => setThreadsFilter(filterParams)}/>
            <ChatThreadList chats={currentsChatThreads}/>
            <Pagination forceCurrentPage={threadsFilter.currentPage} totalResults={totalResults} resultsPerPage={50}
                        onPageChange={(selectedItem) => {
                            setThreadsFilter({...threadsFilter, currentPage: selectedItem});
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        }}/>
        </div>
    );
}



