import {NavigateFunction} from "react-router-dom";
import {setUser} from "./UserService.ts";
import fetchWithAuth from "./fetchWithAuth.ts";
import {getReverseProxy} from "./ConfigService.ts";
import {ResultCode} from "../lib/utils.ts";
import {toast} from "sonner";


export const loginFlow = async (navigate: NavigateFunction) => {
    if (getReverseProxy()) {
        await whoami(navigate);
        navigate('/')
    } else {
        navigate('/login')
    }
}

export const whoami = async (navigate: NavigateFunction) => {
    try {
        const response = await fetchWithAuth('/auth0/whoami', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.status == 401 || response.status == 403) {
            const location = response.headers.get('x-mirakl-login-url')
            window.location.assign(location + '?redirect=/')
        } else {
            fetchWithAuth('/api/user/me', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }).then(
                response => {
                    if (!response.ok) {
                        toast.error(ResultCode.UnknownError)
                        return
                    }
                    return response.json()
                }
            ).then(json => {
                setUser(json)
            })
        }
    } catch (error) {
        console.error('Failed to load user:', error)
        navigate('/login')
    }
}

const csrftokenKey = 'csrftoken';

export const setCSRFToken = (csrfToken: string) => {
    if (typeof window !== 'undefined' && csrfToken && csrfToken !== 'undefined') {
        sessionStorage.setItem(csrftokenKey, csrfToken)
    }
}

export const getCSRFToken = () => {
    if (typeof window !== 'undefined') {
        return sessionStorage.getItem(csrftokenKey)
    }
    return null
}

export const eraseCSRFToken = () => {
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem(csrftokenKey)
    }
    return null
}
