import React, {createContext, ReactNode, useContext, useState} from 'react';
import {KnowledgeNode} from "../models/knowledgeBaseModel.ts";
import {useNavigate} from "react-router-dom";


interface KnowledgeBaseContextType {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    error: string | null;
    setError: (error: string | null) => void;
    setSelectedNode: (node: KnowledgeNode | null) => void;
    knowledgeBaseTree: KnowledgeNode | null;
    setKnowledgeBaseTree: (node: KnowledgeNode | null) => void;
    expandedIds: string[];
    setExpandedIds: (ids: string[]) => void;
    handleNodeSelect: (node: KnowledgeNode) => void;
    setNodeSelectedFromParams: (node: KnowledgeNode | null, sourceId: string | null, source_name: string | null, page_id: string | null) => void;
    selectedNode: KnowledgeNode | null;
    currentDocument: KnowledgeNode | null;
    setCurrentDocument: (node: KnowledgeNode | null) => void;
}

const KnowledgeBaseContext = createContext<KnowledgeBaseContextType | undefined>(undefined);

export const useKnowledgeBase = (): KnowledgeBaseContextType => {
    const context = useContext(KnowledgeBaseContext);
    if (!context) {
        throw new Error('useKnowledgeBase must be used within a KnowledgeBaseProvider');
    }
    return context;
};

interface KnowledgeBaseProviderProps {
    children: ReactNode;
}

export const KnowledgeBaseProvider: React.FC<KnowledgeBaseProviderProps> = ({children}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedNode, setSelectedNode] = useState<KnowledgeNode | null>(null);
    const [currentDocument, setCurrentDocument] = useState<KnowledgeNode | null>(null);
    const [knowledgeBaseTree, setKnowledgeBaseTree] = useState<KnowledgeNode | null>(null);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    const navigate = useNavigate();


    const findPathToNode = (node: KnowledgeNode, id: string, path: KnowledgeNode[] = []): KnowledgeNode[] | null => {
        path.push(node);
        if (node.id === id) {
            return path;
        }
        if (node.children) {
            for (const child of node.children) {
                const foundPath = findPathToNode(child, id, path.slice()); // Create a shallow copy of path
                if (foundPath) {
                    return foundPath;
                }
            }
        }
        return null;
    };

    const findPathToNodeFromSource = (node: KnowledgeNode, source_name: string, page_id: string, path: KnowledgeNode[] = []): KnowledgeNode[] | null => {
        path.push(node);
        if (node.source_name === source_name && node.page_id === page_id) {
            return path;
        }
        if (node.children) {
            for (const child of node.children) {
                const foundPath = findPathToNodeFromSource(child, source_name, page_id, path.slice()); // Create a shallow copy of path
                if (foundPath) {
                    return foundPath;
                }
            }
        }
        return null;
    };

    const setNodeSelectedFromParams = (treeNode: KnowledgeNode | null = null, sourceId: string | null = null, source_name: string | null = null, page_id: string | null = null) => {
        const currentTree = treeNode;
        if (currentTree) {
            const pathToNode = (sourceId) ? findPathToNode(currentTree, sourceId) : findPathToNodeFromSource(currentTree, source_name!, page_id!);
            if (pathToNode) {
                setSelectedNode(pathToNode[pathToNode.length - 1]); // Select the last node in the path
                setExpandedIds(pathToNode.map(node => node.id)); // Expand all nodes in the path
            }
        }
    }

    const handleNodeSelect = (node: KnowledgeNode) => {
        setSelectedNode(node);
        navigate(`/knowledge-base/${node.source_name}/${node.page_id}`);
        const pathToNode = findPathToNode(knowledgeBaseTree!, node.id);
        if (pathToNode) {
            setExpandedIds(pathToNode.map(n => n.id));
        }
    };

    return (
        <KnowledgeBaseContext.Provider
            value={{
                loading,
                setLoading,
                error,
                setError,
                selectedNode,
                setSelectedNode,
                knowledgeBaseTree,
                setKnowledgeBaseTree,
                expandedIds,
                setExpandedIds,
                handleNodeSelect,
                setNodeSelectedFromParams,
                currentDocument,
                setCurrentDocument
            }}>
            {children}
        </KnowledgeBaseContext.Provider>
    );
};
