import {NavigateFunction} from "react-router-dom";
import fetchWithAuth, {getJsonAndHandleError} from "../fetchWithAuth.ts";


export async function updateActiveCollection(collectionName: string, navigate: NavigateFunction): Promise<void> {

    try {
        const response = await fetchWithAuth(`/api/user/change-active-collection`, {
            method: 'POST',
            body: JSON.stringify({"active_collection_name": collectionName}),
        })
        return await getJsonAndHandleError(response, navigate);
    } catch (error) {
        navigate('/')
    }
}

